import React from 'react';
import webScreenshot from '../../../img/Website Screenshot.png';
import {
  SkillsCard,
  TimelineEvent,
  UdemyCard,
} from './JeffGriffithsDev.components';
import { skillsData, timelineData, udemyData } from './JeffGriffithsDev.data';

const JeffGriffithsDev = () => {
  return (
    <div className='content-container personal-website-description'>
      <div className='section-light'>
        <div className='section-container grid grid-gap-12 header-section'>
          <h1 className='large text-primary'>jeffgriffiths.dev</h1>
          <p className='lead'>
            <strong>Dec 2021</strong>: created this website as a means of
            practicing as I learn Web Development; it also stands as an
            interactive CV and portfolio
          </p>
          <a href='/'>
            <img src={webScreenshot} alt='jeffgriffiths.dev Screenshot' />
          </a>
        </div>
      </div>
      <div className='section-dark'>
        <div className='section-container grid grid-gap-12 skills-section'>
          <h1 className='large text-primary'>Skills</h1>
          <h2 className='medium text-light'>Used in this application</h2>

          <div className='skill-card-container grid grid-gap-12'>
            {skillsData
              .filter((v) => v.section === 'used')
              .map((skill) => (
                <SkillsCard
                  title={skill.title}
                  body={skill.body}
                  linkUrl={skill.linkUrl}
                  deviconClass1={skill.deviconClass1}
                  deviconClass2={skill.deviconClass2}
                />
              ))}
          </div>

          <h2 className='medium text-light my-2'>Used in other applications</h2>

          <div className='skill-card-container grid grid-gap-12'>
            {skillsData
              .filter((v) => v.section === 'not used')
              .map((skill) => (
                <SkillsCard
                  title={skill.title}
                  body={skill.body}
                  linkUrl={skill.linkUrl}
                  deviconClass1={skill.deviconClass1}
                  deviconClass2={skill.deviconClass2}
                />
              ))}
          </div>
        </div>
      </div>
      <div className='section-light'>
        <div className='section-container grid grid-gap-12 evolution-section'>
          <h1 className='large text-primary'>Evolution</h1>

          <div className='col-lg-6 col-xlg-6 timeline-container'>
            <h1 className='small text-dark evolution-subsection-header'>
              Iteration Progress
            </h1>
            <ul className='timeline'>
              {timelineData.map((event) => (
                <TimelineEvent
                  iteration={event.iteration}
                  date={event.date}
                  text={event.text}
                />
              ))}
            </ul>
          </div>
          <div className='col-lg-6 col-xlg-6 learning-container'>
            <h1 className='small text-dark evolution-subsection-header'>
              Resources (Udemy)
            </h1>
            <div className='udemy-card-container'>
              {udemyData.map((course) => (
                <UdemyCard
                  title={course.title}
                  creator={course.creator}
                  linkUrl={course.linkUrl}
                  imgSrc={course.imgSrc}
                  altText={course.altText}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JeffGriffithsDev;
