import webScreenshotImg from '../../img/Website Screenshot (portfolio).jpeg';
import sudokuImg from '../../img/Sudoku (portfolio).png';
import rainbowSpiralImg from '../../img/Rainbow Spiral (portfolio).png';
import cssJeffImg from '../../img/CSS Jeff (portfolio).png';
import autoApiTesterImg from '../../img/auto-api-tester (portfolio).png';
import timerBarImg from '../../img/timer-bar (portfolio).png';

export const portfolioLinksData = [
  {
    href: 'https://github.com/jkgriffiths93',
    iconClass: 'fab fa-github-square',
    title: 'GitHub',
    description: 'Open Source Code Portfolio',
  },
  {
    href: 'https://pypi.org/user/jkgriffiths93/',
    iconClass: 'fab fa-python',
    title: 'PyPI',
    description: 'Published Python Packages',
  },
  {
    href: 'https://www.npmjs.com/~jkgriffiths93',
    iconClass: 'fab fa-npm',
    title: 'npm',
    description: 'Published Node.js Packages',
  },
  {
    href: 'https://leetcode.com/jkgriffiths93/',
    iconClass: 'fa fa-code',
    title: 'LeetCode',
    description: 'Coding Challenges',
  },
  {
    href: 'https://cssbattle.dev/player/jkg731',
    iconClass: 'fab fa-css3-alt',
    title: 'CSSBattle',
    description: 'CSS Challenges',
  },
  {
    href: 'https://www.thingiverse.com/jkgriffiths93/designs',
    iconClass: 'fa fa-cubes',
    title: 'Thingiverse',
    description: '3D Printing Designs',
  },
  {
    href: 'https://www.ravelry.com/people/jkgriffiths93',
    iconClass: 'fab fa-ravelry',
    title: 'Ravelry',
    description: 'Knitting Projects',
  },
];

export const projectsData = [
  {
    title: 'auto-api-tester',
    date: '2023-07-01',
    imgReference: autoApiTesterImg,
    imgAltText: 'auto-api-tester in action screenshot',
    tags: ['Python', 'REST API', 'Automation'],
    description: 'Automatic tests to make creating APIs efficient',
    href: 'https://github.com/jkgriffiths93/auto-api-tester',
    archive: false,
  },
  {
    title: 'jeffgriffiths.dev',
    date: '2021-11-01',
    imgReference: webScreenshotImg,
    imgAltText: 'jeffgriffiths.dev home page screenshot',
    tags: ['Express', 'React', 'Node.js', 'Sass'],
    description: "Because there's a lot more to me than a CV",
    href: '/portfolio/jeffgriffiths.dev',
    archive: false,
  },
  {
    title: 'Sudoku',
    date: '2021-09-01',
    imgReference: sudokuImg,
    imgAltText: 'Sudoku game screenshot',
    tags: ['Express', 'React', 'Node.js'],
    description: 'My make of the classic puzzle game',
    href: '/portfolio/sudoku',
    archive: false,
  },
  {
    title: 'Rainbow Spiral',
    date: '2020-11-01',
    imgReference: rainbowSpiralImg,
    imgAltText: 'Rainbow spiral screenshot',
    tags: ['CSS'],
    description: 'Golden Ratio + learning CSS = beautiful art',
    href: '/portfolio/rainbow-spiral',
    archive: true,
  },
  {
    title: 'CSS Jeff',
    date: '2020-11-01',
    imgReference: cssJeffImg,
    imgAltText: 'CSS Jeff screenshot',
    tags: ['CSS'],
    description: 'Self portrait using CSS',
    href: '/portfolio/css-jeff',
    archive: true,
  },
  {
    title: 'timer-bar',
    date: '2017-07-01',
    imgReference: timerBarImg,
    imgAltText: 'timer-bar in action screenshot',
    tags: ['Python'],
    description: 'A visual tool to display progress for a discrete process',
    href: 'https://github.com/jkgriffiths93/timer_bar',
    archive: true,
  },
];
