import React from 'react';

export const ResumeSwitch = ({ handleClick, brSelected }) => (
  <div className='switch-section'>
    <div className='switch-container' onClick={handleClick}>
      <div
        className={`resume-switch br-switch${
          brSelected ? ' selected-resume' : ''
        }`}
      >
        Business
      </div>
      <div
        className={`resume-switch tr-switch${
          brSelected ? '' : ' selected-resume'
        }`}
      >
        Technical
      </div>
    </div>
  </div>
);

export const ResumeURL = ({ text, classes = '', url = null }) =>
  url ? (
    <a className={classes} href={url ? url : undefined}>
      {text}
    </a>
  ) : (
    <p className={classes}>{text}</p>
  );

export const BRSectionHeader = ({ title }) => (
  <>
    <p className='resume-subtitle'>{title}</p>
    <hr className='resume-line' />
  </>
);

export const BRContactHeaders = ({ contact }) => (
  <>
    {contact.map((val, index) => {
      if (val.type === 'name')
        return (
          <p key={index} className='resume-title'>
            {val.text}
          </p>
        );
      return (
        <a
          key={index}
          className='resume-text remove-link-style'
          href={val.link ? val.link : undefined}
        >
          {val.text}
        </a>
      );
    })}
  </>
);

export const BREducation = ({ education }) => {
  // const {
  // school,
  // orgURL = null,
  // dates,
  // location,
  // degree,
  // fieldOfStudy,
  // fosURL = null,
  // gpa,
  // honors = null,
  // description,
  // } = education;

  // poor work around for now for readjusting how data looks, might consider adjusting in future (especially descpription structure)
  const school = education.find((val) => val.type === 'school');
  const dates = education.find((val) => val.type === 'dates');
  const location = education.find((val) => val.type === 'location');
  const degree = education.find((val) => val.type === 'degree');
  const fieldOfStudy = education.find((val) => val.type === 'fieldOfStudy');
  const gpa = education.find((val) => val.type === 'gpa');
  const honors = education.find((val) => val.type === 'honors');
  const description = education.find((val) => val.type === 'description');

  const boldOrNormal = (text, style, index) => {
    return style === 'bold' ? (
      <span key={index} className='resume-bold'>
        {text}
      </span>
    ) : (
      text
    );
  };

  const textParse = (arr) => {
    const usedArr = arr.filter((val) => val.value.brInclude);

    if (usedArr.length === 0) return '';

    let tempText = usedArr[0].value.text;

    if (usedArr.length === 1) return boldOrNormal(tempText, usedArr[0].style);

    let outArr = [];

    let tic = 0;

    usedArr.slice(1).forEach((val, i) => {
      if (usedArr[i].style === val.style) {
        tempText += ', ' + val.value.text;
      } else {
        outArr.push(boldOrNormal(tempText, usedArr[i].style, tic));
        tempText = ', ' + val.value.text;
        tic += 1;
      }
    });

    outArr.push(boldOrNormal(tempText, usedArr[usedArr.length - 1].style));

    const out = (
      <>
        {outArr.map((component, index) => {
          return component;
        })}
      </>
    );
    return out;
  };

  return (
    <>
      <div className='resume-lr'>
        {(school.brInclude || location.brInclude) && (
          <ResumeURL
            text={textParse([
              { value: school, style: 'bold' },
              { value: location },
            ])}
            classes='resume-text resume-lr-l remove-link-style'
            url={school.link}
          />
        )}
        {dates.brInclude && (
          <p className='resume-text resume-lr-r'>{dates.text}</p>
        )}
      </div>
      {(degree.brInclude || gpa.brInclude || honors.brInclude) && (
        <ResumeURL
          text={textParse([
            { value: degree },
            { value: fieldOfStudy },
            { value: gpa },
            { value: honors },
          ])}
          classes='resume-text remove-link-style'
          url={fieldOfStudy.link}
        />
      )}
      <ul>
        {description.values
          .filter((desc) => desc.brInclude)
          .map((desc, index) => (
            <BRDescription key={index} description={desc.text} />
          ))}
      </ul>
    </>
  );
};

export const BRExperience = ({ experience }) => {
  const { organization, orgURL = null, location, roles } = experience;
  return (
    <>
      <div className='resume-lr'>
        <ResumeURL
          text={organization}
          classes='resume-text resume-bold resume-lr-l remove-link-style'
          url={orgURL}
        />
        <p className='resume-text resume-bold resume-lr-r'>{location}</p>
      </div>
      {roles.map((role, index) => {
        return (
          <BRRole
            key={index}
            title={role.title}
            dates={role.dates}
            description={role.description}
          />
        );
      })}
    </>
  );
};

export const BRRole = ({ title, dates, description }) => (
  <>
    <div className='resume-lr'>
      <p className='resume-text resume-italic resume-lr-l'>{title}</p>
      <p className='resume-text resume-lr-r'>{dates}</p>
    </div>
    <ul>
      {description.map((desc, index) => (
        <BRDescription key={index} description={desc.description} />
      ))}
    </ul>
  </>
);

export const BRDescription = ({ description }) => (
  <li className='resume-list-item'>{description}</li>
);

export const TRTitle = ({ text }) => <p className='tr-title'>{text}</p>;

export const TRContactInfo = ({ contact }) => {
  const nameInfo = contact.find((val) => val.type === 'name');
  const contactInfo = contact.filter((val) => val.type !== 'name');

  return (
    <div className='resume-lr-l tr-box tr-name-box'>
      <p className='tr-name'>{nameInfo.text}</p>
      <ul className='icon-list resume-lr-l'>
        {contactInfo.map((val, index) => (
          <li key={index}>
            <span className={`icon ${val.icon} fa-1x`}></span>
            <ResumeURL text={val.text} url={val.link} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TREducation = ({ title, education }) => {
  const headers = education.filter((val) => val.trHeader);
  const body = education.filter((val) => !val.trHeader);

  return (
    <div className='resume-lr-l tr-box tr-other-box'>
      <TRTitle text={title} />
      {headers.map((val, index) => (
        <ResumeURL
          key={index}
          text={val.text}
          classes={val.class}
          url={val.link}
        />
      ))}
      <ul className='icon-list resume-lr-l'>
        {body.map((val, index) => (
          <li key={index}>
            <a href={val.link ? val.link : undefined}>
              <span className={`icon ${val.icon} fa-1x`}></span>
              {val.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TRSkillsHobbies = ({ title, data }) => (
  <div className='resume-lr-l tr-box tr-other-box'>
    <p className='tr-title'>{title}</p>
    <ul className='resume-lr-l'>
      {data.map((val, index) => (
        <li key={index}>
          <a href={val.link ? val.link : undefined}>
            <span className='resume-bold'>{val.mainText}</span>
            {val.parenthesisText ? ` (${val.parenthesisText})` : ''}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export const TRCourses = ({ title, courses }) => (
  <div className='resume-lr-l tr-box tr-other-box'>
    <p className='tr-title'>{title}</p>
    <ul className='resume-lr-l'>
      {courses.map((org, index) => (
        <li className='resume-bold' key={index}>
          <a href={org.link ? org.link : undefined}>{org.organization}</a>
          <ul className='resume-lr-l'>
            {org.courses
              .filter((val) => val.trInclude)
              .map((course, index2) => (
                <li key={index2}>
                  <a href={course.link ? course.link : undefined}>
                    {course.text}
                  </a>
                </li>
              ))}
          </ul>
        </li>
      ))}
    </ul>
  </div>
);

export const TROverview = ({ title, data }) => (
  <div className='tr-personal-overview tr-eop-box'>
    <p className='resume-lr-l tr-title'>{title}</p>
    {data.map((val, index) => (
      <p className='resume-lr-l' key={index}>
        {val.text}
      </p>
    ))}
  </div>
);

export const TRProjectsRoles = ({ data, roleFlag = false }) => (
  <>
    <div className='resume-lr'>
      <a
        className={`resume-text resume-lr-l remove-link-style ${
          roleFlag ? 'resume-italic' : 'resume-bold'
        }`}
        href={data.link ? data.link : undefined}
      >
        {data.title}
      </a>

      <p className={`resume-text resume-lr-r${roleFlag ? '' : ' resume-bold'}`}>
        {data.dates}
      </p>
    </div>
    <ul className='resume-lr-l'>
      {data.description
        .filter((val) => val.trInclude)
        .map((val, index) => (
          <li key={index}>{val.description}</li>
        ))}
    </ul>
  </>
);

export const TRExperience = ({ title, experiences }) => (
  <div className='tr-experience tr-eop-box'>
    <p className='resume-lr-l tr-title'>{title}</p>
    {experiences.map((experience, index) => (
      <div key={index}>
        <div className='resume-lr'>
          <a
            className='resume-text resume-bold resume-lr-l remove-link-style'
            href={experience.orgURL ? experience.orgURL : undefined}
          >
            {experience.organization}
          </a>
          <p className='resume-text resume-bold resume-lr-r'>
            {experience.location}
          </p>
        </div>
        {experience.roles
          .filter((val) => val.trInclude)
          .map((role, index2) => (
            <TRProjectsRoles
              key={`l2: ${index2}`}
              data={role}
              roleFlag={true}
            />
          ))}
      </div>
    ))}
  </div>
);

export const TRProjects = ({ title, projects }) => (
  <div className='tr-projects tr-eop-box'>
    <p className='resume-lr-l tr-title'>{title}</p>
    {projects.map((val, index) => (
      <TRProjectsRoles key={index} data={val} />
    ))}
  </div>
);
