import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PageContainer from './components/layout/PageContainer';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

import Home from './views/home/Home';
import About from './views/about/About';
import BlogLanding from './views/blog/BlogLanding';
import BlogGroupLanding from './views/blog/BlogGroupLanding';
import BlogPost from './views/blog/BlogPost';
import Resume from './views/resume/Resume';
import PortfolioLanding from './views/portfolio/PortfolioLanding';
import JeffGriffithsDev from './views/portfolio/jeffgriffiths_dev/JeffGriffithsDev';
import Sudoku from './views/portfolio/sudoku/Sudoku';
import RainbowSpiral from './views/portfolio/rainbow_spiral/RainbowSpiral';
import CssJeff from './views/portfolio/css_jeff/CssJeff';
import Error from './views/error/Error';

const App = () => (
  <Router>
    <PageContainer>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/blog' element={<BlogLanding />} />
        <Route
          exact
          path='/blog/post/:blogGroup'
          element={<BlogGroupLanding />}
        />
        <Route
          exact
          path='/blog/post/:blogGroup/:date'
          element={<BlogPost />}
        />
        <Route exact path='/resume' element={<Resume />} />
        <Route exact path='/portfolio' element={<PortfolioLanding />} />
        <Route
          exact
          path='/portfolio/jeffgriffiths.dev'
          element={<JeffGriffithsDev />}
        />
        <Route exact path='/portfolio/sudoku' element={<Sudoku />} />
        <Route
          exact
          path='/portfolio/rainbow-spiral'
          element={<RainbowSpiral />}
        />
        <Route exact path='/portfolio/css-jeff' element={<CssJeff />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </PageContainer>
  </Router>
);

export default App;
