import React from 'react';

export const AboutCard = ({ about, order }) => {
  const { title, imgSrc, altImgText, text } = about;

  const light = order % 2 === 0;

  const ImgComponent = () => (
    <div className='col-xlg-4 col-lg-4 about-img-container'>
      <img src={imgSrc} alt={altImgText} />
    </div>
  );

  return (
    <div className={`section-${light ? 'light' : 'dark'}`}>
      <div className='section-container grid'>
        <h1 className={`medium text-primary ${!light && 'dark-title'}`}>
          {title}
        </h1>
        {light && <ImgComponent />}
        <p className={`col-xlg-8 col-lg-8 ${!light && 'dark-description'}`}>
          {text}
        </p>
        {!light && <ImgComponent />}
      </div>
    </div>
  );
};
