export const contactData = [
  {
    type: 'name',
    brInclude: true,
    trInclude: true,
    // icon: 'fas fa-map-pin',
    text: 'Jeff Griffiths',
    // link: 'https://www.atlantaga.gov',
  },
  {
    type: 'location',
    brInclude: true,
    trInclude: true,
    icon: 'fas fa-map-pin',
    text: 'Atlanta, GA',
    link: 'https://www.atlantaga.gov',
  },
  {
    type: 'email',
    brInclude: true,
    trInclude: true,
    icon: 'fas fa-envelope',
    text: 'jkgriffiths93@gmail.com',
    link: 'mailto:jkgriffiths93@gmail.com',
  },
  {
    type: 'phone',
    brInclude: true,
    trInclude: true,
    icon: 'fa fa-phone',
    text: '(404) 804-3249',
    link: 'tel:404-804-3249',
  },
  {
    type: 'website',
    brInclude: false,
    trInclude: true,
    icon: 'fa fa-laptop',
    text: 'jeffgriffiths.dev',
    link: 'https://www.jeffgriffiths.dev',
  },
];

export const experienceData = [
  {
    organization: 'Redpath Capital',
    orgURL: 'https://redpathcapital.co.uk',
    location: 'Oxford, UK',
    brInclude: true,
    trInclude: true,
    roles: [
      {
        title: 'Search Fund Intern',
        dates: 'Jan 2023 – May 2023',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              '$300k capital raised through research and analysis contributions',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              '20+ hours/month saved in deal sourcing activity via Python web scraping and aggregation service',
          },
          {
            brInclude: false,
            trInclude: true,
            description:
              '20+ hrs/mo saved sourcing potential acquisitions via Python scraping service',
          },
        ],
      },
    ],
  },
  {
    organization: 'Home Depot',
    orgURL: 'https://corporate.homedepot.com/about',
    location: 'Atlanta, GA, USA',
    brInclude: true,
    trInclude: true,
    roles: [
      {
        title:
          'Finance & Strategy Manager – One Supply Chain, Supply Chain Finance',
        dates: 'Jan 2022 - Sep 2022',
        brInclude: true,
        trInclude: false,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              'Advised executive team on $1.2B investment in customer delivery capabilities through complex long range financial modeling',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              '$10M annual savings through exploring new fulfillment strategy in geographically constrained market',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Managed small team; used weekly check-ins and monthly feedback sessions to develop independently motivated contributors',
          },
        ],
      },
      {
        title:
          'Finance & Strategy Manager – Low-Cost Provider, Supply Chain Finance',
        dates: 'May 2021 - Jan 2022',
        brInclude: true,
        trInclude: false,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              '$10M annual savings through developing and operationalizing labor forecast variance analysis',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              '$13M annual savings by leading collaboration and modeling effort with various cross-functional stakeholders for long range financial model of warehouse automation ($8M) and labor insourcing ($5M) initiatives; achieved executive team approval',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Built multi-site NPV analysis template used by broader team for numerous business cases (100+ hrs/yr productivity savings)',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Initialized and facilitated monthly Supply Chain Finance team (60+ members) technical training to catalyze group knowledge share and relationship development in virtual environment',
          },
        ],
      },
      {
        title: 'Finance & Strategy Manager',
        dates: 'May 2021 - Sep 2022',
        brInclude: false,
        trInclude: true,
        description: [
          {
            brInclude: false,
            trInclude: true,
            description:
              'Managed small team; used weekly check-ins and monthly feedback sessions to develop independently motivated contributors',
          },
          {
            brInclude: false,
            trInclude: true,
            description:
              'Built multi-site NPV analysis template used by broader team for numerous business cases (100+ hrs/yr productivity savings)',
          },
        ],
      },
      {
        title: 'Finance Project Leader – HD Pro, Supply Chain Finance',
        dates: 'Feb 2020 - May 2021',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              '$1.2M savings achieved leading business partners in determining Q2 transportation cost savings initiatives and subsequently driving results through detailed weekly tracking of each initiative',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Increased engagement and accountability in actionable cost reduction/risk mitigation opportunities through revision of monthly business review deck to focus conversation on volume, rate, and operational metrics (recognized as critical by EVP of SC)',
          },
          {
            brInclude: false,
            trInclude: true,
            description:
              'Automated majority of annual planning process using Google BigQuery (80+ hrs/yr productivity savings)',
          },
          {
            brInclude: false,
            trInclude: true,
            description:
              'Innovated analytical approach to show cost dependency on volume, rate, and operational metrics; commended by EVP of Supply Chain (vital during COVID)',
          },
        ],
      },
    ],
  },
  {
    organization: 'McKinsey & Company',
    orgURL: 'https://www.mckinsey.com/about-us/overview',
    location: 'Atlanta, GA | Houston, TX, USA',
    brInclude: true,
    trInclude: true,
    roles: [
      {
        title: 'Business Analyst',
        dates: 'Aug 2018 – Feb 2020',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              '+$100M/yr savings opportunity identified in restructuring engineering services client through spans and layers optimization, salary band standardization, and other means; helped create implementation plan to achieve savings in accelerated timeline',
          },
          {
            brInclude: true,
            trInclude: true,
            description:
              '$6M/yr margin increase discovered by applying advanced analytics to B2B pricing strategy at food processing corporation',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              '$10M/yr reduction in procurement spend achieved by leading digital procurement events at industrial equipment manufacturer',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              '$2M/yr reduction executed in external spend leading workshops and coaching operators one-on-one at a power plant',
          },
        ],
      },
    ],
  },
  {
    organization: 'Blue Raven Solar',
    orgURL: 'https://blueravensolar.com/about-us/',
    location: 'American Fork, UT, USA',
    brInclude: true,
    trInclude: true,
    roles: [
      {
        title: 'Data Engineer',
        dates: 'Jan – Aug 2018',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              'Created automated tool to gather, synthesize, and interpret data from four disparate platforms using Python; enabled company to monitor individual performance relative to production guarantee (deemed “mission critical” by executive team)',
          },
          {
            brInclude: false,
            trInclude: true,
            description:
              'Created Python application to gather, synthesize, and interpret data from four disparate platforms to monitor performance relative to production guarantee',
          },
          {
            brInclude: true,
            trInclude: true,
            description:
              'Automated invoice verification process; $100,000 one-time savings; similar carryforward savings expected by CFO',
          },
        ],
      },
      {
        title: 'Operations Intern',
        dates: 'Jun – Dec 2017',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: true,
            description:
              'Increased corporate workforce by 10% in two-weeks; recruiting analysis during process saved $40k in future recruiting efforts',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Created and initiated a corporate-wide CO2 reduction initiative, projected to reduce company CO2 output by 37.5 tons a year',
          },
        ],
      },
    ],
  },
  {
    organization: 'Africa International Business Project',
    location: 'Lilongwe, MALAWI',
    brInclude: true,
    trInclude: true,
    roles: [
      {
        title: 'Intern',
        dates: 'May 2017',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: true,
            description:
              'Collaborated with Malawian entrepreneurs to create potential for soap business to increase their income by 75%',
          },
        ],
      },
    ],
  },
  {
    organization: 'Valero Energy Corperation',
    orgURL: 'https://www.valero.com/about',
    location: 'Memphis, TN, USA',
    brInclude: false,
    trInclude: true,
    roles: [
      {
        title: 'Control Systems Engineering Intern',
        dates: 'May 2016 – Aug 2016',
        brInclude: false,
        trInclude: true,
        description: [
          {
            brInclude: false,
            trInclude: true,
            description:
              'Wrote and tested 3000 line VBA automated statistical analysis application to continually improve 29 Advanced Process Control models',
          },
        ],
      },
    ],
  },
  {
    organization: 'PRISM Lab, Professor John D. Hedengren',
    orgURL: 'https://apm.byu.edu/prism/',
    location: 'Provo, UT, USA',
    brInclude: false,
    trInclude: true,
    roles: [
      {
        title: 'Optimization and Control Research Assistant',
        dates: 'Mar 2015 – Apr 2016',
        brInclude: false,
        trInclude: true,
        description: [
          {
            brInclude: false,
            trInclude: true,
            description:
              'Constructed and refined models of complex power grid using GAMS language to optimize carbon capture system ($119 Million per year per plant)',
          },
        ],
      },
    ],
  },
];

export const educationData = [
  {
    type: 'school',
    brInclude: true,
    trInclude: true,
    text: 'Brigham Young University',
    // icon: '',
    link: 'https://www.byu.edu',
    class: 'resume-bold',
    trHeader: true,
  },
  {
    type: 'degreeFieldOfStudy',
    brInclude: false,
    trInclude: true,
    text: 'B.S. Chemcial Engineering',
    // icon: '',
    link: 'https://chemicalengineering.byu.edu',
    trHeader: true,
  },
  {
    type: 'location',
    brInclude: true,
    trInclude: true,
    text: 'Provo, UT, USA',
    icon: 'fas fa-map-pin',
    link: 'https://www.provo.org',
  },
  {
    type: 'dates',
    brInclude: true,
    trInclude: true,
    text: 'Dec 2017',
    icon: 'fa fa-calendar',
    // link: '',
  },
  {
    type: 'degree',
    brInclude: true,
    trInclude: false,
    text: 'Bachelor of Science',
    // icon: '',
    // link: '',
  },
  {
    type: 'fieldOfStudy',
    brInclude: true,
    trInclude: false,
    text: 'Chemical Engineering',
    icon: '',
    link: 'https://chemicalengineering.byu.edu',
  },
  {
    type: 'gpa',
    brInclude: true,
    trInclude: true,
    text: '3.93/4.00',
    icon: 'fas fa-graduation-cap',
    // link: '',
  },
  {
    type: 'chapterPresident',
    brInclude: false,
    trInclude: true,
    text: 'Chapter President, Tau Beta Pi',
    icon: 'fa fa-gears',
    link: 'https://www.tbp.org/home.cfm',
  },
  {
    type: 'leaderScholar',
    brInclude: false,
    trInclude: true,
    text: 'Weidman Leader Scholar',
    icon: 'fas fa-compass',
    // link: '',
  },
  {
    type: 'triathlete',
    brInclude: false,
    trInclude: true,
    text: 'Collegiate Triathlete',
    icon: 'fas fa-swimmer',
    // link: '',
  },
  {
    type: 'honors',
    brInclude: false,
    trInclude: false,
    text: 'cum laude',
    icon: '',
    // link: '',
  },
  {
    type: 'description',
    brInclude: true,
    trInclude: false,
    values: [
      {
        brInclude: true,
        trInclude: false,
        text: 'Weidman Leader Scholar (1 of 3 awarded), BYU Heritage Scholarship (4-year tuition, academic and leadership achievement)',
        icon: '',
        // link: '',
      },
      {
        brInclude: true,
        trInclude: false,
        text: 'Collegiate Triathlete, Mountain Collegiate Triathlon Conference',
        icon: '',
        // link: '',
      },
    ],
  },
];

export const leadershipData = [
  {
    organization: 'National Tau Beta Pi Engineering Honor Society',
    orgURL: 'https://www.tbp.org',
    location: 'San Diego, CA, USA',
    brInclude: true,
    trInclude: false,
    roles: [
      {
        title: 'Chair, National Finance Committee',
        dates: 'Oct 2016',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              'Led a diverse team to investigate and draw conclusions on five financial issues of the national organization',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Executed presentation that persuaded convention of 500 people to approve committee’s recommendations',
          },
        ],
      },
    ],
  },
  {
    organization: 'Tau Beta Pi Engineering Honor Society',
    orgURL: 'https://www.tbp.org',
    location: 'Provo, UT, USA',
    brInclude: true,
    trInclude: false,
    roles: [
      {
        title: 'Chapter President',
        dates: 'Jan 2016 - Jan 2017',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              'Led 15 officers serving 200 members; reorganized officer structure to better serve broad membership',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Directed efforts in promoting and aiding members in applying to national scholarship ($38,000 rewarded to BYU members)',
          },
        ],
      },
    ],
  },
  {
    organization: 'The Church of Jesus Christ of Latter-day Saints',
    orgURL: 'https://www.churchofjesuschrist.org',
    location: 'New York, NY, USA',
    brInclude: true,
    trInclude: false,
    roles: [
      {
        title: 'Full-Time Representative',
        dates: 'Sep 2012 – Aug 2014',
        brInclude: true,
        trInclude: true,
        description: [
          {
            brInclude: true,
            trInclude: false,
            description:
              'Mastered a foreign language (Spanish) to effectively reach target demographic',
          },
          {
            brInclude: true,
            trInclude: false,
            description:
              'Motivated 50+ multinational volunteers to collaborate and accomplish mutual goals in a foreign environment',
          },
        ],
      },
    ],
  },
];

export const skillsAndInterestsData = [
  {
    brInclude: true,
    trInclude: false,
    text: 'Extensive experience with Excel, PowerPoint, Python, VBA, Google BigQuery, Tableau, SQL, MERN stack, and Alteryx',
    icon: '',
    // link: '',
  },
  {
    brInclude: true,
    trInclude: false,
    text: 'Spanish and Italian- high proficiency; Arabic, French, Portuguese, ASL, German- basic proficiency',
    icon: '',
    // link: '',
  },
  {
    brInclude: true,
    trInclude: false,
    text: 'Eagle Scout; organized book drive, collected 1300+ books for Child Development and Family Support Organization',
    icon: '',
    // link: '',
  },
  {
    brInclude: true,
    trInclude: false,
    text: 'Knitting, cooking, web development, triathlons, canyoneering, and 3D Printing',
    icon: '',
    // link: '',
  },
];

export const trSkillsData = [
  {
    trInclude: true,
    // link: '',
    mainText: 'Web Development',
    parenthesisText: 'MERN stack, Heroku, Sass, Redux, JWT',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Automation',
    parenthesisText:
      'Python - Selenium, Requests, BeautifulSoup, Openpyxl; Excel – VBA, Dynamic Arrays',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'ML/Advanced Analytics',
    parenthesisText: 'Python – Scipy, Numpy, Pandas, Sklearn, TensorFlow',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Databases',
    parenthesisText:
      'SQL – Google BigQuery, MySQL, Microsoft SQL Server, PostgreSQL; NoSQL—MongoDB/Atlas',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Visualization',
    parenthesisText: 'Tableau, Python – Matplotlib, Plotly, Dash, seaborn',
  },
  {
    trInclude: true,
    link: 'http://github.com/jkgriffiths93',
    mainText: 'Version Control',
    parenthesisText: 'Git, Github',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Financial Modeling',
    parenthesisText: 'Excel/Python',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'PowerPoint',
    parenthesisText: '',
  },
];

export const relaventCoursesData = [
  {
    organization: 'University',
    trInclude: true,
    link: 'https://chemicalengineering.byu.edu/undergraduate/courses-planning',
    courses: [
      {
        trInclude: true,
        // link: '',
        text: 'Calc 1-3, Diff EQ, Linear Algebra',
      },
      {
        trInclude: true,
        // link: '',
        text: 'Stats: Engineers and Scientists',
      },
      {
        trInclude: true,
        link: 'http://apmonitor.com/do/',
        text: 'Dynamic Optimization',
      },
      {
        trInclude: true,
        link: 'https://ignite.byu.edu/che541/index.html',
        text: 'Numerical Methods',
      },
    ],
  },
  {
    organization: 'Udemy',
    trInclude: true,
    link: 'https://www.udemy.com/user/jeff-griffiths-5/',
    courses: [
      {
        trInclude: true,
        link: 'https://www.udemy.com/course/the-complete-web-development-bootcamp/',
        text: 'Complete WebDev Bootcamp',
      },
      {
        trInclude: true,
        link: 'https://www.udemy.com/course/the-ultimate-mysql-bootcamp-go-from-sql-beginner-to-expert/',
        text: 'Ultimate MySQL Bootcamp',
      },
      {
        trInclude: true,
        link: 'https://www.udemy.com/course/mern-stack-front-to-back/',
        text: 'MERN Stack Front to Back',
      },
      {
        trInclude: true,
        link: 'https://www.udemy.com/course/machinelearning/',
        text: 'Machine Learning A - Z',
      },
      {
        trInclude: true,
        link: 'https://www.udemy.com/course/deeplearning/',
        text: 'Deep Learning A - Z',
      },
    ],
  },
];

export const hobbiesData = [
  {
    trInclude: true,
    link: 'https://www.ravelry.com/projects/jkgriffiths93',
    mainText: 'Knitting',
    parenthesisText: 'blankets, scarfs, sweaters',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Cooking/Baking',
    parenthesisText: 'Pizza = Specialty',
  },
  {
    trInclude: true,
    // link: '',
    mainText: 'Learning Languages',
    parenthesisText:
      'Spanish/Italian – high proficiency; Portuguese, French, Arabic, German, ASL – basic',
  },
];

export const projectsData = [
  {
    title: 'To Do Coach App',
    // link: '',
    dates: 'Mar 2023 – Present',
    trInclude: true,
    description: [
      {
        trInclude: true,
        description:
          'In progress MERN stack project to create weekly and daily checklists with guided feedback to help user see both progress and opportunities to user time better',
      },
      {
        trInclude: true,
        description:
          'Uses JWT authenticator for use authentication, Redux for state management',
      },
    ],
  },
  {
    title: 'Automated API Tester',
    link: 'https://github.com/jkgriffiths93/auto-api-tester',
    dates: 'Jun 2023 – Jul 2023',
    trInclude: true,
    description: [
      {
        trInclude: true,
        description:
          'Python service to test API for expected failures/successes',
      },
      {
        trInclude: true,
        description:
          'Saved 20+ hours in testing time during build out of To Do Coach App',
      },
    ],
  },
  {
    title: 'Personal Website (jeffgriffiths.dev)',
    link: 'https://www.jeffgriffiths.dev/projects/jeffgriffiths.dev',
    dates: 'Aug 2021 – Dec 2021',
    trInclude: true,
    description: [
      {
        trInclude: true,
        description:
          'MERN stack website to host portfolio projects, resumes, blog, and “About Me”',
      },
    ],
  },
];

export const personalOverviewData = [
  {
    trInclude: true,
    text: 'I am a relentless learner. I focus on opportunities that teach me how the world works so I can make it a better place. I am seeking to further learn by applying my technical and finance skills to help scale a growing company.',
  },
];
