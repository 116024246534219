import React, { useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { dateString } from './Blog.utils';

export const PostPreview = ({ post, blogGroup = false }) => {
  const { title, imgSrc = null, imgAltText = null, body, blogGroupUrl } = post;
  const stringLength = 200;

  const date = dateString(post, '/');

  const bodyShort = blogGroup
    ? body
    : body.substring(
        0,
        stringLength + body.substring(stringLength).indexOf(' ')
      ) + '...';

  const hrefBase = `/blog/post/${blogGroupUrl}`;
  const href = `${hrefBase}${blogGroup ? '' : `/${dateString(post, '-')}`}`;

  return (
    <a href={href} className='post-preview bg-dark grid'>
      <img className='col-xlg-2 col-lg-2' src={imgSrc} alt={imgAltText} />
      <div className='blog-header-container col-xlg-3 col-lg-3'>
        <h2
          className={`text-white small${blogGroup ? ' blog-group-header' : ''}`}
        >
          {title}
        </h2>
        {!blogGroup && (
          <p className='x-small text-primary'>
            <Moment format='MM/DD/YYYY'>{date}</Moment>
          </p>
        )}
      </div>
      {/* {imgSrc && (
        <img className='col-xlg-2 col-lg-2' src={imgSrc} alt={imgAltText} />
      )} */}
      <p
        // className={`blog-preview text-light x-small ${
        //  imgSrc ? 'col-xlg-7 col-lg-7' : 'col-xlg-9 col-lg-9'
        //}`}
        className='blog-preview text-light x-small col-xlg-7 col-lg-7'
      >
        {bodyShort}
      </p>
    </a>
  );
};

export const PostPage = ({ post }) => {
  // const { title, instaCode, body, captionLineLengths, comment, portrait } =
  const { title, body, instaCode } = post;

  // can change this logic if ever needed-- setting it to 4 would do the single regression model rather than four scenario specific regression models
  // const scenarioOverride = null;
  return (
    <>
      <h1 className='large text-primary'>{title}</h1>
      <p className='lead'>
        <Moment format='MM/DD/YYYY'>{dateString(post, '/')}</Moment>
      </p>
      <div className='blog-body-container'>
        <iframe
          class='instagram-embed'
          src={`https://www.instagram.com/p/${instaCode}/embed/captioned/`}
          frameborder='0'
          allowfullscreen
        ></iframe>
        {/* <Instapost instaCode={instaCode} /> */}
        {/* <InstagramEmbed
          instaCode={instaCode}
          captionLineLengths={captionLineLengths}
          comment={comment}
          portrait={portrait}
          scenarioOverride={scenarioOverride}
        /> */}
        <p className='blog-body'>{body}</p>
      </div>
    </>
  );
};

// export const InstagramEmbed = ({
//   instaCode,
//   captionLineLengths,
//   comment,
//   portrait,
//   scenarioOverride = null,
//   // calcMethod = 2,
// }) => {
//   const bufferLines = 1; // adjustment to buffer calculation-- better bigger than smaller
//   // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [iframeWidth, setIframeWidth] = useState(0);
//   const iframeRef = useRef(null);

//   useEffect(() => {
//     const handleResize = () => {
//       const iframe = iframeRef.current;
//       const { width } = iframe.getBoundingClientRect();
//       setIframeWidth(width);
//       // setWindowWidth(window.innerWidth);
//     };

//     const handleLoad = () => {
//       const iframe = iframeRef.current;
//       const { width } = iframe.getBoundingClientRect();
//       setIframeWidth(width);
//     };

//     const currentIframeRef = iframeRef.current;

//     handleResize(); // set initial width
//     window.addEventListener('resize', handleResize);
//     currentIframeRef.current.addEventListener('load', handleLoad);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//       currentIframeRef.current.removeEventListener('load', handleLoad);
//     };
//   }, []);

// const resizeHeight1 = () => {
//   const mobile = windowWidth <= 700;

//   // calculate the character cutoff
//   const m = mobile ? 0.144 : 0.0665390996491907;
//   const b = mobile ? -2.6 : -11.60502958049802;
//   const maxWidth = mobile ? 700 : 1100;
//   const minWidth = mobile ? 1 : 701;

//   let usedWindowWidth = Math.min(windowWidth, maxWidth);
//   usedWindowWidth = Math.max(usedWindowWidth, minWidth);

//   const characterCutoff = m * usedWindowWidth + b;

//   // calculate the estimated number of lines
//   // const lineEstimate = (lineLengths, comments, characterCutoff) => {
//   let estimatedLines = 0;
//   for (const lineLength of captionLineLengths) {
//     estimatedLines += Math.max(1, Math.ceil(lineLength / characterCutoff));
//   }
//   const commentLine = comment ? 1 : 0;
//   estimatedLines += 2 + commentLine + bufferLines; // adding two for the two automatic lines instagram adds at top of every post
//   // };

//   // choose proper multi-linear regression constants based on the scenario
//   const regressCoeff = [
//     // 0 = non-mobile + landscape
//     {
//       rConst: 815.540555,
//       rWindowSize: -0.258076,
//       rEstLines: 3.511503,
//       rMobileSize: 0,
//       rPortrait: 0,
//     },
//     // 1 = non-mobile + portrait
//     {
//       rConst: 330.859193,
//       rWindowSize: -0.073175,
//       rEstLines: 16.227614,
//       rMobileSize: 0,
//       rPortrait: 0,
//     },
//     // 2 = mobile + landscape
//     {
//       rConst: 610.923441,
//       rWindowSize: -0.402304,
//       rEstLines: 10.206628,
//       rMobileSize: 0,
//       rPortrait: 0,
//     },
//     // 3 = mobile + portrait
//     {
//       rConst: 272.898077,
//       rWindowSize: -0.093019,
//       rEstLines: 18.815036,
//       rMobileSize: 0,
//       rPortrait: 0,
//     },
//     // 4 = altogether (shouldn't use, included just in case)
//     {
//       rConst: 440.306393,
//       rWindowSize: -0.102963,
//       rEstLines: 14.34018,
//       rMobileSize: -59.975458,
//       rPortrait: -43.366783,
//     },
//   ];

//   const picRegressCoeff = [
//     // 0 = non-mobile + landscape
//     {
//       rPicConst: -25.358388,
//       rPicWindowSize: 0.300251,
//       rPicMobileSize: 0,
//       rPicPortrait: 0,
//     },
//     // 1 = non-mobile + portrait
//     {
//       rPicConst: -41.227856,
//       rPicWindowSize: 0.495611,
//       rPicMobileSize: 0,
//       rPicPortrait: 0,
//     },
//     // 2 = mobile + landscape
//     {
//       rPicConst: -61.15555,
//       rPicWindowSize: 0.749778,
//       rPicMobileSize: 0,
//       rPicPortrait: 0,
//     },
//     // 3 = mobile + portrait
//     {
//       rPicConst: -101.294737,
//       rPicWindowSize: 1.240105,
//       rPicMobileSize: 0,
//       rPicPortrait: 0,
//     },
//     // 4 = altogether (shouldn't use, included just in case)
//     {
//       rPicConst: -251.214356,
//       rPicWindowSize: 0.525841,
//       rPicMobileSize: 365.461499,
//       rPicPortrait: 193.576441,
//     },
//   ];

//   const mobileSizeBinary = mobile ? 1 : 0;
//   const portraitBinary = portrait ? 1 : 0;
//   const calculatedScenario = mobileSizeBinary * 2 + portraitBinary * 1;
//   const usedScenario = scenarioOverride
//     ? scenarioOverride
//     : calculatedScenario;

//   const usedCoeff = regressCoeff[usedScenario];
//   const usedPicCoeff = picRegressCoeff[usedScenario];

//   const { rConst, rWindowSize, rEstLines, rMobileSize, rPortrait } =
//     usedCoeff;

//   const { rPicConst, rPicWindowSize, rPicMobileSize, rPicPortrait } =
//     usedPicCoeff;

//   // const picHeight = rPortrait ?

//   const height =
//     rConst +
//     rWindowSize * usedWindowWidth +
//     rEstLines * estimatedLines +
//     rMobileSize * mobileSizeBinary +
//     rPortrait * portraitBinary;

//   const picHeight =
//     rPicConst +
//     rPicWindowSize * usedWindowWidth +
//     rPicMobileSize * mobileSizeBinary +
//     rPicPortrait * portraitBinary;

//   return height + picHeight;
// };

// const resizeHeight = () => {
//   // const mobile = windowWidth <= 700;

//   // calculate the character cutoff
//   const m = 0.16533895124697212;
//   const b = -3.9258469516563608;
//   // const maxWidth = mobile ? 700 : 1100;
//   // const minWidth = mobile ? 1 : 701;

//   // let usedWindowWidth = Math.min(windowWidth, maxWidth);
//   // usedWindowWidth = Math.max(usedWindowWidth, minWidth);

//   const characterCutoff = m * iframeWidth + b;

//   // calculate the estimated number of lines
//   // const lineEstimate = (lineLengths, comments, characterCutoff) => {
//   let estimatedLines = 0;
//   for (const lineLength of captionLineLengths) {
//     estimatedLines += Math.max(1, Math.ceil(lineLength / characterCutoff));
//   }
//   const commentLine = comment ? 1 : 0;
//   estimatedLines += 2 + commentLine + bufferLines; // adding two for the two automatic lines instagram adds at top of every post
//   // };

//   // choose proper multi-linear regression constants based on the scenario
//   const regressCoeff = [
//     // 0 = landscape
//     {
//       rConst: 215.573777,
//       rIframeWidth: 0.00203,
//       rEstLines: 18.580565,
//       rPortrait: 0,
//     },
//     // 1 = portrait
//     {
//       rConst: 242.200089,
//       rIframeWidth: -0.021062,
//       rEstLines: 17.66644,
//       rPortrait: 0,
//     },
//   ];

//   const picRegressCoeff = [
//     // 0 = landscape
//     {
//       rPicConst: -0.864089,
//       rPicIframeWidth: 0.749207,
//       rPicPortrait: 0,
//     },
//     // 1 = portrait
//     {
//       rPicConst: -1.473708,
//       rPicIframeWidth: 1.247535,
//       rPicPortrait: 0,
//     },
//   ];

//   const portraitBinary = portrait ? 1 : 0;
//   // const calculatedScenario = portraitBinary * 1;
//   // const usedScenario = scenarioOverride
//   //   ? scenarioOverride
//   //   : calculatedScenario;

//   const usedCoeff = regressCoeff[portraitBinary];
//   const usedPicCoeff = picRegressCoeff[portraitBinary];

//   const { rConst, rIframeWidth, rEstLines, rPortrait } = usedCoeff;

//   const { rPicConst, rPicIframeWidth, rPicPortrait } = usedPicCoeff;

//   // const picHeight = rPortrait ?

//   const height =
//     rConst +
//     rIframeWidth * iframeWidth +
//     rEstLines * estimatedLines +
//     rPortrait * portraitBinary;

//   const picHeight =
//     rPicConst + rPicIframeWidth * iframeWidth + rPicPortrait * portraitBinary;

//   return height + picHeight;
// };

// const resizedHeight = calcMethod === 1 ? resizeHeight1() : resizeHeight2();
// const resizedHeight = resizeHeight();

//   return (
//     <iframe
//       ref={iframeRef}
//       height={resizedHeight}
//       title={`Embedded Instagram Post - ${instaCode}`}
//       className='instagram-embed'
//       //src={`https://www.instagram.com/p/${instaCode}/embed/captioned/`}
//       src={`https://www.instagram.com/p/${instaCode}/?utm_source=ig_embed&utm_campaign=loading`}
//       frameBorder='0'
//       allowFullScreen
//     />
//   );
// };
