import React from 'react';
import {
  TRContactInfo,
  TREducation,
  TRSkillsHobbies,
  TRCourses,
  TRProjects,
  TRExperience,
  TROverview,
} from './Resume.components';
import {
  contactData,
  educationData,
  trSkillsData,
  relaventCoursesData,
  hobbiesData,
  projectsData,
  experienceData,
  personalOverviewData,
} from './Resume.data';

const TechnicalResume = () => {
  return (
    <>
      {/* Title Section */}
      <div className='tr-name bg-dark'>
        {/* Contact Info */}
        <TRContactInfo contact={contactData.filter((val) => val.trInclude)} />
      </div>

      {/* Other Section */}
      <div className='tr-other bg-light'>
        {/* Education */}
        <TREducation
          title='Education'
          education={educationData.filter((val) => val.trInclude)}
        />

        {/* Skills */}
        <TRSkillsHobbies
          title='Skills'
          data={trSkillsData.filter((val) => val.trInclude)}
        />

        {/* Courses */}
        <TRCourses
          title='Relavent Courses'
          courses={relaventCoursesData.filter((val) => val.trInclude)}
        />

        {/* Hobbies */}
        <TRSkillsHobbies
          title='Hobbies'
          data={hobbiesData.filter((val) => val.trInclude)}
        />
      </div>

      <div className='tr-experience'>
        <div className='resume-lr-l tr-box tr-eop'>
          {/* Overview */}
          <TROverview
            title='Personal Overview'
            data={personalOverviewData.filter((val) => val.trInclude)}
          />

          {/* Work Experience */}
          <TRExperience
            title='Work Experience'
            experiences={experienceData.filter((val) => val.trInclude)}
          />

          {/* Projects */}
          <TRProjects title='Projects' projects={projectsData} />
        </div>
      </div>
    </>
  );
};

export default TechnicalResume;
