import React from 'react';
import { PostPreview } from './Blog.components';
import { blogGroupData } from './Blog.data';

const BlogLanding = () => {
  return (
    <div className='content-container blog-landing-page'>
      <div className='section-light'>
        <div className='section-container grid'>
          <h1 className='large text-primary'>Learning to Love to Learn</h1>
          <p className='lead'>
            A collection of my experiences and thoughts as I journey through
            life
          </p>
          <div className='post-container'>
            {blogGroupData.map((post, index) => {
              return <PostPreview key={index} post={post} blogGroup={true} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLanding;
