import React from 'react';
import { useParams } from 'react-router-dom';
import { PostPage } from './Blog.components';
import { blogData } from './Blog.data';
import { dateString } from './Blog.utils';

const BlogPost = ({ params }) => {
  const { date } = useParams();
  const post = blogData.find((post) => dateString(post, '-') === date);

  return (
    <div className='content-container blog-post-page'>
      <div className='section-light'>
        <div className='section-container grid'>
          <PostPage post={post} />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
