import React from 'react';
import { ArtContainer } from '../Portfolio.components';
import { RainbowSpiralAll } from './RainbowSpiral.components';

const RainbowSpiral = () => {
  return (
    <div className='content-container'>
      <div className='section-light'>
        <div className='section-container grid'>
          <ArtContainer
            title='Rainbow Spiral'
            explanation="Nov 2020: Combo of div's and CSS to make spiral based off golden
            ratio"
          >
            <div className='rs-rsstart rs-c8'>
              <RainbowSpiralAll layers={13} />
            </div>
          </ArtContainer>
        </div>
      </div>
    </div>
  );
};

export default RainbowSpiral;
