import React, { useState, useEffect, useMemo } from 'react';
import {
  Instructions,
  SudokuBoard,
  SudokuBottomNumbers,
  SudokuGameplayButton,
  SudokuOptionsButton,
  SudokuTimer,
} from './Sudoku.components';
import {
  gameplayButtons,
  instructionsData,
  optionsButtons,
} from './Sudoku.data';
import {
  clickEvent,
  falseBoard,
  initialFocus,
  initialGameplayButtons,
  // initialGameplayText,
  initialOptionsButtons,
  initialTimer,
  keyDownEvent,
  keyUpEvent,
  zeroBoard,
} from './Sudoku.utils';
// import {
// bottonNumberEvent,
// gameplayButtonEvent,
// optionsButtonEvent,
// sudokuBoardEvent,
// } from './Sudoku.utils.old';

const Sudoku = () => {
  const [optionsButtonsState, setOptionsButtonsState] = useState(
    initialOptionsButtons
  );

  const [sudokuBoardState, setSudokuBoardState] = useState({
    sudokuNumbers: JSON.parse(JSON.stringify(zeroBoard)),
    // easy 20
    // sudokuNumbers: [
    //   [0, 0, -3, -9, -8, -7, -4, 0, 0],
    //   [0, -7, 0, 0, -4, 0, 0, -5, 0],
    //   [-2, 0, 0, 0, -3, 0, 0, 0, -7],
    //   [-5, -6, 0, -1, 0, -4, 0, -7, -3],
    //   [0, 0, 0, -7, 0, -6, 0, 0, 0],
    //   [-9, -2, 0, -3, 0, -8, 0, -6, -4],
    //   [-1, 0, 0, 0, -6, 0, 0, 0, -8],
    //   [0, -8, 0, 0, -7, 0, 0, -4, 0],
    //   [0, 0, -6, -8, -1, -3, -9, 0, 0],
    // ],
    sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
    sudokuWarning: JSON.parse(JSON.stringify(falseBoard)),
  });

  const [focus, setFocus] = useState(initialFocus);

  const [timer, setTimer] = useState(initialTimer);

  const [gameplayButtonsState, setGameplayButtonsState] = useState(
    initialGameplayButtons
  );

  // const [gameplayButtonText, setGameplayButtonText] =
  //   useState(initialGameplayText);

  const [shiftKeyWasPen, setShiftKeyWasPen] = useState(true);

  const stateVariables = useMemo(() => {
    const out = () => ({
      optionsButtonsState: optionsButtonsState,
      sudokuBoardState: sudokuBoardState,
      focus: focus,
      timer: timer,
      // gameplayButtonText: gameplayButtonText,
      gameplayButtonsState: gameplayButtonsState,
      shiftKeyWasPen: shiftKeyWasPen,
      setOptionsButtonsState: setOptionsButtonsState,
      setSudokuBoardState: setSudokuBoardState,
      setFocus: setFocus,
      setTimer: setTimer,
      // setGameplayButtonText: setGameplayButtonText,
      setGameplayButtonsState: setGameplayButtonsState,
      setShiftKeyWasPen: setShiftKeyWasPen,
    });
    return out();
  }, [
    optionsButtonsState,
    sudokuBoardState,
    focus,
    timer,
    gameplayButtonsState,
    // gameplayButtonText,
    shiftKeyWasPen,
  ]);

  // listen for keyboard events
  useEffect(() => {
    const keyDownEventEntry = (e) => {
      keyDownEvent(e, stateVariables);
    };

    const keyUpEventEntry = (e) => {
      keyUpEvent(e, stateVariables);
    };

    window.addEventListener('keydown', keyDownEventEntry);
    window.addEventListener('keyup', keyUpEventEntry);

    return () => {
      window.removeEventListener('keydown', keyDownEventEntry);
      window.removeEventListener('keyup', keyUpEventEntry);
    };
  }, [stateVariables]);

  // timer functionality
  useEffect(() => {
    let interval = null;
    if (timer.isActive) {
      interval = setInterval(() => {
        setTimer({ ...timer, seconds: timer.seconds + 1 });
      }, 1000);
    } else if (!timer.isActive && timer.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const onClick = (e) => {
    if (e === null) return;
    clickEvent(e, stateVariables);
  };

  return (
    <div className='content-container sudoku'>
      <div className='section-light'>
        <div className='section-container grid'>
          <h1 className='large text-primary'>Sudoku</h1>
          <p className='lead'>
            Sep 2021: classic sudoku puzzle with various features and
            functionality
          </p>
          <div className='col-xlg-3 col-lg-3 options-column'>
            <p className='my-1 medium'>Options</p>
            {optionsButtons.map(
              (buttonInfo, index) =>
                optionsButtonsState[buttonInfo.name].visible && (
                  <SudokuOptionsButton
                    key={index}
                    buttonInfo={buttonInfo}
                    onClick={onClick}
                    selected={optionsButtonsState[buttonInfo.name].selected}
                  />
                )
            )}
          </div>
          <div className='col-xlg-6 col-lg-6 sudoku-column'>
            <div className='sudoku-outer-container'>
              <p className='my-1 medium'>Sudoku!</p>
              <SudokuBoard
                sudokuBoardState={sudokuBoardState}
                focus={focus}
                optionsButtons={optionsButtonsState}
                onCellClick={onClick}
              />
              <SudokuBottomNumbers
                bottomNumber={focus.number}
                rapidFill={optionsButtonsState.rapidFill.selected}
                onClick={onClick}
              />
            </div>
          </div>
          <div className='col-xlg-3 col-lg-3 gameplay-column'>
            <p className='my-1 medium'>Gameplay</p>
            <SudokuTimer timer={timer} />
            {gameplayButtons.map((buttonInfo, index) => (
              <SudokuGameplayButton
                key={index}
                buttonInfo={buttonInfo}
                buttonState={gameplayButtonsState}
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='section-dark'>
        <div className='section-container grid'>
          <Instructions
            puzzleMode={optionsButtonsState.puzzleMode.selected}
            instructions={instructionsData}
            stateVariables={stateVariables}
          />
        </div>
      </div>
    </div>
  );
};

export default Sudoku;
