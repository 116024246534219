export const skillsData = [
  {
    title: 'MongoDB',
    body: 'Data is fairly static on this webpage and only I need to edit it; I might connect to AtlasDB in later versions',
    linkUrl: 'https://www.mongodb.com',
    deviconClass1: 'devicon-mongodb-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
  {
    title: 'Express',
    body: 'Framework for setting up the server, routing, APIs, etc.',
    linkUrl: 'https://expressjs.com',
    deviconClass1: 'devicon-express-original',
    deviconClass2: 'colored',
    section: 'used',
  },
  {
    title: 'React',
    body: 'Front end templating and interactivity using hooks',
    linkUrl: 'https://reactjs.org',
    deviconClass1: 'devicon-react-original',
    deviconClass2: 'colored',
    section: 'used',
  },
  {
    title: 'Node',
    body: 'Used to build both the backend and frontend',
    linkUrl: 'https://nodejs.org/en/',
    deviconClass1: 'devicon-nodejs-plain',
    deviconClass2: 'colored',
    section: 'used',
  },
  {
    title: 'Heroku',
    body: 'Deployed on Heroku using Heroku CLI',
    linkUrl: 'https://www.heroku.com/about',
    deviconClass1: 'devicon-heroku-plain',
    deviconClass2: 'colored',
    section: 'used',
  },
  {
    title: 'Sass',
    body: 'Used while theme building and then implemented as a compiled CSS',
    linkUrl: 'https://sass-lang.com',
    deviconClass1: 'devicon-sass-plain',
    deviconClass2: 'colored',
    section: 'used',
  },
  {
    title: 'Redux',
    body: 'No authentication or global state needed, so opted to just use useState for each individual page instead',
    linkUrl: 'https://redux.js.org',
    deviconClass1: 'devicon-redux-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
  {
    title: 'JWT',
    body: 'No authentication needed for app, but have experience using JWT for authentication',
    linkUrl: 'https://jwt.io',
    section: 'not used',
  },
  {
    title: 'Bootstrap',
    body: "Have used Bootstrap in the past but unless I'm creating a\nsimple UI for complex backend, I opt to create Bootstrap like functionality using Sass and React",
    linkUrl: 'https://getbootstrap.com/',
    deviconClass1: 'devicon-bootstrap-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
  {
    title: 'EJS',
    body: "I've used EJS extensively in the past but now opt to use React",
    linkUrl: 'https://ejs.co/',
    section: 'not used',
  },
  {
    title: 'JQuery',
    body: 'Indirectly using Javascript through react, but have in the past built out event listeners and interacted with the DOM directly using Javascript',
    linkUrl: 'https://jquery.com',
    deviconClass1: 'devicon-jquery-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
  {
    title: 'JavaScript',
    body: 'Indirectly using Javascript through react, but have in the past built out event listeners and interacted with the DOM directly using Javascript',
    linkUrl: 'https://www.javascript.com',
    deviconClass1: 'devicon-javascript-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
  {
    title: 'MySQL',
    body: 'Opted for NoSQL (MongoDB specifically) for scalability in future projects, but have extensive experience building and querying SQL tables from profession as a Corporate Finance Professional',
    linkUrl: 'https://www.javascript.com',
    deviconClass1: 'devicon-mysql-plain',
    deviconClass2: 'colored',
    section: 'not used',
  },
];

export const timelineData = [
  {
    iteration: 1,
    date: 'Aug 2021',
    text: 'Front end only-- static HTML, Bootstrap, with some functionality through Javascript/JQuery',
  },
  {
    iteration: 2,
    date: 'Sep 2021',
    text: 'Backend using Node and Exress with front end similar to first iteration, but rendered by an EJS engine',
  },
  {
    iteration: 3,
    date: 'Dec 2021',
    text: 'MERN stack with a theme building exercise using plain HTML and Sass',
  },
];

export const udemyData = [
  {
    title: 'The Complete WebDev Bootcamp',
    creator: 'Dr. Angela Yu',
    linkUrl:
      'https://www.udemy.com/course/the-complete-web-development-bootcamp/',
    imgSrc: 'https://img-c.udemycdn.com/course/480x270/1565838_e54e_14.jpg',
    altText: 'Complete Web Dev Bootcamp course pic',
  },
  {
    title: 'MERN Stack Front to Back',
    creator: 'Brad Traversy',
    linkUrl: 'https://www.udemy.com/course/mern-stack-front-to-back/',
    imgSrc: 'https://img-b.udemycdn.com/course/480x270/1646980_23f7_2.jpg',
    altText: 'MERN Stack course pic',
  },
];
