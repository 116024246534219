import React, { useState } from 'react';
import {
  PortfolioLinksCard,
  ProjectCard,
  ProjectsFilterPill,
  ProjectsFilterSelector,
} from './Portfolio.components';
import { portfolioLinksData, projectsData } from './Portfolio.data';

const PortfolioLanding = () => {
  const [tagFilter, setTagFilter] = useState([
    { name: 'mongoDB', text: 'MongoDB', value: false },
    { name: 'express', text: 'Express', value: false },
    { name: 'react', text: 'React', value: false },
    { name: 'node', text: 'Node.js', value: false },
    { name: 'restApi', text: 'REST API', value: false },
    { name: 'sass', text: 'Sass', value: false },
    { name: 'css', text: 'CSS', value: false },
    { name: 'python', text: 'Python', value: false },
    { name: 'automation', text: 'Automation', value: false },
    { name: 'all', text: 'n/a', value: true },
  ]);

  const toggleOneFilter = (tagName, value = null) => {
    const tagIndex = tagFilter.findIndex((obj) => obj.name === tagName);
    const newState = [...tagFilter];
    newState[tagIndex].value =
      value !== null ? value : !newState[tagIndex].value;
    setTagFilter(newState);
  };

  const checkForTrue = () => {
    const notAll = tagFilter.filter((tag) => tag.name !== 'all');
    const notAllValues = notAll.map((tag) => tag.value);
    const outValue = notAllValues.every((v) => v === false);
    toggleOneFilter('all', outValue);
  };

  const filterClick = (e) => {
    const { name } = e.target.dataset;
    toggleOneFilter(name);
    checkForTrue();
  };

  const filteredProjects = tagFilter
    .filter((tag) => tag.name !== 'all' && tag.value === true)
    .map((tag) => tag.text);
  const visibleProjects = projectsData.filter((project) => {
    // console.log(tagFilter.filter((v) => (v.name = 'all'))[0]);
    return (
      tagFilter.find((v) => v.name === 'all').value ||
      filteredProjects.some((tag) => project.tags.includes(tag))
    );
  });

  return (
    <div className='content-container project-landing-page'>
      <div className='section-light'>
        <div class='section-container grid'>
          <h1 class='large text-primary'>Portfolio Websites</h1>
          <p class='lead'>Links to my profiles on various portfolio websites</p>
          <div class='grid grid-gap-12 portfolio-links'>
            {portfolioLinksData.map((link, index) => (
              <PortfolioLinksCard
                key={index}
                href={link.href}
                iconClass={link.iconClass}
                title={link.title}
                description={link.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='section-dark'>
        <div className='section-container grid'>
          <h1 className='large text-primary'>Projects</h1>
          <p className='lead'>
            Recent projects demonstrating my software engineering capabilities
          </p>
          <div div className='tag-filter-container myb-1 grid'>
            <div className='col-lg-8 col-xlg-8 myb-1 selected-tag-filters'>
              {tagFilter.filter((v) => v.name !== 'all' && v.value).length !==
              0 ? (
                tagFilter
                  .filter((v) => v.name !== 'all' && v.value)
                  .map((filter, index) => (
                    <ProjectsFilterPill
                      key={index}
                      text={filter.text}
                      name={filter.name}
                      includeX={'true'}
                      onClick={filterClick}
                    />
                  ))
              ) : (
                <ProjectsFilterPill
                  text={<>&nbsp; &nbsp; All &nbsp; &nbsp;</>}
                />
              )}
            </div>
            <div className='col-lg-4 col-xlg-4 dropdown tag-filter-drowpdown myb-1'>
              <button className='btn btn-primary tag-filter'>
                Project Tag Filter
              </button>
              <div className='dropdown-content bg-primary'>
                {tagFilter
                  .filter((v) => v.name !== 'all')
                  .map((filter, index) => (
                    <ProjectsFilterSelector
                      key={index}
                      text={filter.text}
                      name={filter.name}
                      selected={tagFilter
                        .find((v) => v.name === filter.name)
                        .value.toString()}
                      onClick={filterClick}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className='project-card-container grid grid-gap-12'>
            {visibleProjects
              .filter((val) => !val.archive)
              .map((project, index) => (
                <ProjectCard key={index} project={project} />
              ))}
          </div>
        </div>
      </div>
      <div className='section-light'>
        <div class='section-container grid'>
          <h1 class='large text-primary'>Archived Projects</h1>
          <p class='lead'>
            Sample of previous projects that helped me learn software
            engineering
          </p>
          <div class='project-card-container grid grid-gap-12'>
            <div className='project-card-container grid grid-gap-12'>
              {visibleProjects
                .filter((val) => val.archive)
                .map((project, index) => (
                  <ProjectCard key={index} project={project} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioLanding;
