import React from 'react';

const Navbar = () => {
  return (
    <nav className='navbar bg-dark'>
      <div className='nav-content'>
        <h1>
          <a href='/'>
            <i className='fas fa-project-diagram text-logo'></i> Jeff Griffiths
          </a>
        </h1>
        <ul>
          <li>
            <a href='/'>Home</a>
          </li>
          <li>
            <a href='/about'>About</a>
          </li>
          <li>
            <a href='/blog'>Blog</a>
          </li>
          <li>
            <a href='/resume'>Resume</a>
          </li>
          <li>
            <a href='/portfolio'>Portfolio</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
