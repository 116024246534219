import React from 'react';

export const SkillsCard = ({
  linkUrl,
  deviconClass1,
  deviconClass2,
  title,
  body,
}) => {
  var deviconClass = '';
  deviconClass1 && (deviconClass = deviconClass + deviconClass1 + ' ');
  deviconClass2 && (deviconClass = deviconClass + deviconClass2);
  return (
    <a
      className='skill-card col-lg-3 col-xlg-2'
      href={linkUrl}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='skill-title-container'>
        {({ deviconClass1 } || { deviconClass2 }) && (
          <i className={deviconClass}></i>
        )}
        <p className='small skill-card-title'>{title}</p>
      </div>
      <ul>
        <li className='x-small text-white'>{body}</li>
      </ul>
    </a>
  );
};

export const TimelineEvent = ({ iteration, date, text }) => (
  <li className='event' data-date={date}>
    <h3>Iteration {iteration}</h3>
    <p>{text}</p>
  </li>
);

export const UdemyCard = ({ title, creator, linkUrl, imgSrc, altText }) => (
  <a href={linkUrl} target='_blank' rel='noopener noreferrer'>
    <div className='udemy-card'>
      <img src={imgSrc} alt={altText} />
      <h1 className='udemy-title small'>{title}</h1>
      <h2 className='udemy-author x-small'>{creator}</h2>
    </div>
  </a>
);
