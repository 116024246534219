import React from 'react';
import { ArtContainer } from '../Portfolio.components';

const CssJeff = () => {
  return (
    <div className='content-container cssjeff'>
      <div className='section-light'>
        <div className='section-container grid'>
          <ArtContainer
            title='CSS Jeff'
            explanation="Nov 2020: self portrait attempt using only div's and CSS"
          >
            <div className='head skin'>
              <div className='hair-box box'>
                <div className='head-hair-padding'></div>
                <div className='head-hair hair'></div>
              </div>
              <div className='forehead-box box'></div>
              <div className='eyebrows-box box'>
                <div className='eyebrow-padding'></div>
                <div className='eyebrow hair'></div>
                <div className='unibrow'></div>
                <div className='eyebrow hair'></div>
              </div>
              <div className='eyes-box box'>
                <div className='eye eye-left'>
                  <div className='pupil pupil-left'></div>
                </div>
                <div className='eye eye-right'>
                  <div className='pupil pupil-right'></div>
                </div>
              </div>
              <div className='nose-box box'>
                <div className='sub-nose-box'>
                  <div className='nose-pad'></div>
                  <div className='nose-parent'>
                    <div className='nose-cover skin'></div>
                  </div>
                </div>
              </div>
              <div className='mouth-box box'>
                <div className='mouth-pad'></div>
                <div className='mouth-parent'>
                  <div className='mouth-cover skin'></div>
                </div>
              </div>
              <div className='chin-box box'></div>
            </div>
          </ArtContainer>
        </div>
      </div>
    </div>
  );
};

export default CssJeff;
