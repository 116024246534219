import youngJeffImg from '../../img/About/Baby Jeff (Beginnings).jpg';
import familyImg from '../../img/About/Immediate Family (family).jpeg';
import canyoneeringImg from '../../img/About/Canyoneering (hobbies).jpg';
import victoriaFallsImg from '../../img/About/Victoria Falls (Travels).jpeg';
import arduinoImg from '../../img/About/Arduino (Education).jpeg';
import mckinseyImg from '../../img/About/McKinsey Last Day (Career).jpg';
import mountainImg from '../../img/About/Malawi mountain (aspirations).jpeg';

export const aboutData = [
  {
    title: 'Beginnings',
    imgSrc: youngJeffImg,
    altImgText: 'Young Jeff',
    text: 'I was born and raised in the suburbs of Atlanta Georgia. With a teacher mom and an engineer dad, education was strongly emphasized as a powerful tool in my home. School was never difficult for me, and I was a well-behaved kid. I also swam a lot, starting on a year-round club when I was nine and stopping my junior year of high school after burning out. In later elementary school and middle school, I was a little awkward and experienced some minor bullying, but I made inclusive friends as I started high school and was able to come out of my shell. I lucked out to be raised by a great family in great place, and I wouldn’t change much about it!',
  },
  {
    title: 'Family',
    imgSrc: familyImg,
    altImgText: "Jeff's Immediate Family",
    text: 'I grew up with a mom and a dad that are still together and a sister who is three years older than me. We were pretty boring growing up, in the best sense! There was never much drama or anything like that in the Griffiths household. My parents taught good principles and lent my sister and me a decent amount of freedom to learn for ourselves. I have tried to replicate those teachings as I start my own family. My wife Clorisa and I met at BYU in Utah, bonding over being from Georgia (she claims my biscuits and gravy won her heart). After being married for a few years, we had our daughter Rosie who is a particular but fun girl. Later came James, an always-on-the-move and happy boy. We enjoy going on walks outside, eating at new restaurants together, and finding adventure in the every day.',
  },
  {
    title: 'Hobbies',
    imgSrc: canyoneeringImg,
    altImgText: 'Jeff Canyoneering',
    text: 'I love to learn, explore, and create—I feel like those elements are at the heart of what I like to do. Canyoneering is an activity I love where one rappels into a canyon, clambers through it, and then hikes out. I love it because it allows me to get away (usually no cell signal), problem solve (how are we going to get out of this hole?), and face fears (like 100 ft free rappels). I also love triathlons as they both push me to my limits as well as be diverse in my athletic outlets. Cooking is a fun and pragmatic hobby. I love that I can create something that fulfills a basic human need, but with extra effort, I can make fulfilling that need a personally enjoyable and communal bonding experience. I recently got a 3D printer and have similarly loved the creation aspect of the hobby, that if I can imagine something in my mind and build it using software, I can then hold a plastic version of that thing in my hand. Web development is similarly an incredible creation-oriented hobby. I love coding and have found web development a great place for challenging myself with analytical problems while being able to create something that can easily be deployed to an essentially limitless audience. I hope to continue to build on these hobbies and take others along on my journey of learning, exploring, and creating.',
  },
  {
    title: 'Travels',
    imgSrc: victoriaFallsImg,
    altImgText: 'Jeff at Victoria Falss',
    text: 'I didn’t leave the US until I was 23 years old. Prior to that I visited many states (many visits coming during one of our many Griffiths family cross-country road trips) and enjoyed seeing the different landscapes and regional cultures. I really caught the travel bug while living in New York as I served a Spanish-speaking mission for my church. After meeting people like Rafael who loved life no matter what and Paulina who showed love through food, I wanted to fully experience the cultures that shaped these wonderful people. Since then, I’ve been able to visit places such as Tobago on my honeymoon, Malawi on study abroad, Mexico for a local wedding, Japan for business, and England where my wife pursued a masters degree. I hope to continue to travel and learn about this wonderful world!',
  },
  {
    title: 'Education',
    imgSrc: arduinoImg,
    altImgText: 'Arduino control lab',
    text: 'I’m a nerd, have been since an early age. My parents taught me the value of education from an early age, and that combined with my curiosity of how the world works drove me to put a lot of energy into school. I attended BYU, and because I enjoyed my AP Chemistry class so much, I decided to study Chemical Engineering. I loved the understanding of the physical world that Chemical Engineering gave me. While I didn’t have an official emphasis, my engineering electives were analytics focused such as the Dynamic Optimization class (modeling, estimation, and advanced process control) and a computational methods class (programming numerical approaches to solving PDEs, etc.). At the same time, I felt a strong desire to get a more diverse education, so I opted to take lots of classes outside of the engineering school including Italian, Arabic, international business, linguistics, advanced spreadsheet analysis, golf, scuba diving, social dance, design thinking, speed reading, and a few others. I learned a ton while attending BYU and am grateful for my time there.',
  },
  {
    title: 'Career',
    imgSrc: mckinseyImg,
    altImgText: "Jeff's last day at McKinsey",
    text: 'Though I originally aspired to get my PhD, after joining a research lab in undergrad, I found that I preferred doing and building. I pursued an internship opportunity at an oil refinery and learned a ton but found that the prospect of what I would be doing as an engineer there was not exciting to me. After talking to some friends, I learned about management consulting. While preparing to recruit with consulting firms, I interned with and then continued to work at Blue Raven Solar, a small residential solar sales and installation company. I loved being a part of a small and growing team—wearing different hats and blazing trails was immensely enjoyable. Though enjoyable, I decided to still pursue consulting for its learning opportunities. I landed a role at McKinsey’s Houston office in the energy capital of the world. It was a tough experience, but I learned a ton. From general problem solving to storytelling to working under pressure, I came out a better person. Eventually a combination of family circumstances a desire to own rather than to advise led me to join at Home Depot’s Supply Chain Finance group. There I held several positions, working on FP&A focusing on transportation, building business cases for cost-cutting warehouse initiatives, and doing long range modeling for our One Supply Chain initiative. I loved Home Depot’s culture and gained a lot of great finance, operations, and technical skills along the way. I left Home Depot when we move to Oxford, England for my wife to get her MBA. While there, I spent most of my time with my son (born a couple months before we moved) but managed to squeeze in an internship with a search fund. I now plan to join a growing company with the hopes of drawing on my variety of experiences to help with the scaling process. Throughout my career, I’ve always strived to go where I can learn the most, and I don’t anticipate that changing anytime soon. ',
  },
  {
    title: 'Aspirations',
    imgSrc: mountainImg,
    altImgText: 'Jeff on mountain in Malawi',
    text: 'I want to be a leader in the sustainable energy industry while also raising a loving, happy family. Since I was in high school, I’ve been interested in everything related to energy—the physics, the economics, the politics, etc. I’ve also loved the outdoors, having been on many backpacking trips with my dad growing up that showed me how energizing being in nature is for me. After watching the Deepwater Horizon tragedy play out while in high school, I started on a journey to create a space where both life-sustaining energy production and the breath-taking outdoors coexist peacefully. With so many new technologies around sustainable energy are being developed, I intend to lead an organization in commercializing solutions to help produce and distribute energy in ways that leave the Earth beautiful for me and my kids. Family is also extremely important to me. I’m grateful to have had a loving and supportive family and hope to pass that invaluable gift to my kids. I hope to help my kids explore their curiosities and become service-oriented individuals. I hope that we can travel and explore as a family to see others’ perspectives, and in so doing expand our own perspectives. My wife and I plan to make our home an inclusive environment where others can come and feel valued and loved. Having meaningful relationships with my family while pursuing a challenging career is harder than I imagined, but flexibility and frequent communication are helping to enable these dreams.',
  },
];
