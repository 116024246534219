import React from 'react';
import {
  HomeTitleText,
  IconOverview,
  LearnMoreButton,
} from './Home.components';
import jeffImg from '../../img/Jeff in Mountains.jpeg';

const Home = () => {
  return (
    <div className='content-container home-page'>
      <div className='section-light'>
        <div className='section-container grid'>
          <div className='col-xlg-6 col-lg-6 title-text-container'>
            <HomeTitleText text='Who' />
            <HomeTitleText text='is' />
            <HomeTitleText text='Jeff' />
            <HomeTitleText text='Griffiths?' />
          </div>
          <div className='col-xlg-6 col-lg-6 title-image-container'>
            <img className='title-image' src={jeffImg} alt='jeff-outdoors' />
          </div>
        </div>
      </div>
      <div className='section-dark image-cover'>
        <div className='section-container grid'>
          <IconOverview
            icon='fas fa-puzzle-piece'
            title='Problem Solver'
            description={`Ex-McKinsey consultant + engineering degree: not many problems he
              can't break down and solve.`}
          />
          <IconOverview
            icon='fas fa-code'
            title='Technical Mastermind'
            description={`Python, Excel, VBA, BigQuery, SQL, Web Development, Tableau to
              calculate, automate, visualize... You name it, he does it.`}
          />
          <IconOverview
            icon='fas fa-heart'
            title='Master Empathizer'
            description={`Leader, collaborator, and story teller who takes time to
              understand his audience so he knows how to best help them.`}
          />
        </div>
      </div>
      <div className='section-light image-cover'>
        <div className='section-container grid'>
          <h3 className='large'>Learn More!</h3>
          <LearnMoreButton
            href='/about'
            icon='far fa-user-circle'
            text='About Jeff'
          />
          <LearnMoreButton
            href='/projects/jeffgriffiths.dev'
            icon='fas fa-laptop-code'
            text='This Website'
          />
          <LearnMoreButton href='/resume' icon='far fa-file' text='Resume' />
        </div>
      </div>
    </div>
  );
};

export default Home;
