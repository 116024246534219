import React from 'react';
import {
  BRContactHeaders,
  BRDescription,
  BREducation,
  BRExperience,
  BRSectionHeader,
} from './Resume.components';
import {
  contactData,
  experienceData,
  educationData,
  leadershipData,
  skillsAndInterestsData,
} from './Resume.data';

const BusinessResume = () => {
  const filterData = (data) =>
    data.filter((experience) => {
      if (!experience.brInclude) return false;
      experience.roles = experience.roles.filter((role) => {
        if (!role.brInclude) return false;
        role.description = role.description.filter((desc) => desc.brInclude);
        return true;
      });
      return true;
    });

  const filteredExperienceData = filterData(experienceData);
  const filteredLeadershipData = filterData(leadershipData);

  return (
    <>
      {/* Header */}
      <div className='resume-header'>
        <BRContactHeaders
          contact={contactData.filter((val) => val.brInclude)}
        />
      </div>

      {/* Experience */}
      <div className='resume-experience resume-section'>
        <BRSectionHeader title='Experience' />
        {filteredExperienceData.map((experience, index) => (
          <BRExperience key={index} experience={experience} />
        ))}
      </div>

      {/* Education */}
      <div className='resume-education resume-section'>
        <BRSectionHeader title='Education' />
        {/* {educationData.map((edu, index) => ( */}
        {/* <BREducation key={index} education={edu} /> */}
        {/* ))} */}
        <BREducation education={educationData} />
      </div>

      {/* Leadership */}
      <div className='resume-leadership resume-section'>
        <BRSectionHeader title='Leadership' />
        {filteredLeadershipData.map((experience, index) => (
          <BRExperience key={index} experience={experience} />
        ))}
      </div>

      {/* Skills & Interests */}
      <div className='resume-skills-interests resume-section'>
        <BRSectionHeader title='Skills & Interests' />
        <ul>
          {skillsAndInterestsData
            .filter((desc) => desc.brInclude)
            .map((desc, index) => (
              <BRDescription key={index} description={desc.text} />
            ))}
        </ul>
      </div>
    </>
  );
};

export default BusinessResume;
