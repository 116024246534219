// Action event routers --------------------------------------------------------

// key press events
export const keyDownEvent = (e, stateVariables) => {
  const { key, shiftKey, code } = e;
  const keyNumber = code.substring(5, 7);
  const isNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
    keyNumber
  );
  const isArrow = key.substring(0, 5) === 'Arrow';
  switch (true) {
    // number key press
    case isNumber && !shiftKey:
      return numberKeyPressEvent(e, stateVariables);
    // number key press with shift
    case isNumber && shiftKey:
      return numberKeyPressWithShiftEvent(e, stateVariables);
    // arrow press
    case isArrow && !shiftKey:
      return arrowKeyPressEvent(e, stateVariables);
    // array key press with shift
    case isArrow && shiftKey:
      return arrowKeyPressWithShiftEvent(e, stateVariables);
    case key === 'Shift':
      return shiftKeyPressEvent(stateVariables);
    // backspace key press
    case key === 'Backspace':
      return backspaceKeyPressEvent(stateVariables);
    default:
      break;
  }
};

// key release events
export const keyUpEvent = (e, stateVariables) => {
  const { key } = e;

  if (key === 'Shift') return shiftKeyReleaseEvent(stateVariables);
};

// click events
export const clickEvent = (e, stateVariables) => {
  const { name } = e.target.dataset;

  switch (name) {
    // options buttons
    case 'puzzleMode':
      return puzzleModeEvent(name, stateVariables);
    case 'initialInput':
      return initialInputEvent(name, stateVariables);
    case 'errorWarning':
      return errorWarningEvent(name, stateVariables);
    case 'highlightSelected':
      return highlightSelectedEvent(name, stateVariables);
    case 'crosshairs':
      return crosshairsEvent(name, stateVariables);
    case 'arrowScrollOff':
      return arrowScrollOffEvent(name, stateVariables);
    case 'autoRemovePencil':
      return autoRemovePencilEvent(name, stateVariables);
    case 'rapidFill':
      return rapidFillEvent(name, stateVariables);
    case 'penInput':
      return penInputEvent(name, stateVariables);
    // sudoku cell
    case 'sudokuCell':
      return sudokuCellEvent(e, stateVariables);
    // bottom number
    case 'bottomNumber':
      return bottomNumberEvent(e, stateVariables);
    // gameplay buttons
    case 'startTimer':
      return startTimerEvent(name, stateVariables);
    case 'resetTimer':
      return resetTimerEvent(name, stateVariables);
    case 'autofillPencil':
      return autofillPencilEvent(name, stateVariables);
    case 'newPuzzle':
      return newPuzzleEvent(stateVariables);
    case 'difficulty':
      return difficultyEvent(e, stateVariables);
    case 'solve':
      return solveEvent(stateVariables);
    case 'resetInput':
      return resetInputEvent(stateVariables);
    default:
      break;
  }
};

// Action events ---------------------------------------------------------------

// ----- Key press events -----
const numberKeyPressEvent = (e, stateVariables) => {
  numberKeyPress(e, stateVariables);
};

const numberKeyPressWithShiftEvent = (e, stateVariables) => {
  numberKeyPress(e, stateVariables);
};

const arrowKeyPressEvent = (e, stateVariables) => {
  arrowKeyPress(e, stateVariables);
};

const arrowKeyPressWithShiftEvent = (e, stateVariables) => {
  arrowKeyPress(e, stateVariables, true);
};

const shiftKeyPressEvent = (stateVariables) => {
  shiftKeyToggle(stateVariables, true);
};

const backspaceKeyPressEvent = (stateVariables) => {
  backspaceKeyPress(stateVariables);
};

// ----- Key release events -----
const shiftKeyReleaseEvent = (stateVariables) => {
  shiftKeyToggle(stateVariables, false);
};

// ----- Click events -----

// options buttons
const puzzleModeEvent = (name, stateVariables) => {
  const { optionsButtonsState, gameplayButtonsState, setGameplayButtonsState } =
    stateVariables;
  const { puzzleMode } = optionsButtonsState;
  const { newPuzzle, difficulty, solve } = gameplayButtonsState;

  let newOptionsButtonsState = JSON.parse(JSON.stringify(optionsButtonsState));

  // if switching from manual to auto...
  if (puzzleMode.selected) {
    newOptionsButtonsState.initialInput = { selected: false, visible: false };
    setGameplayButtonsState({
      ...gameplayButtonsState,
      newPuzzle: { ...newPuzzle, visible: true },
      difficulty: { ...difficulty, visible: true },
      solve: { ...solve, text: ['Show Solution'] },
    });
  } else {
    newOptionsButtonsState.initialInput = { selected: false, visible: true };
    setGameplayButtonsState({
      ...gameplayButtonsState,
      newPuzzle: { ...newPuzzle, visible: false },
      difficulty: { ...difficulty, visible: false },
      solve: { ...solve, text: ['Attempt Solve'] },
    });
  }

  return toggleOptionsButton([name], {
    ...stateVariables,
    optionsButtonsState: newOptionsButtonsState,
  });
};

const initialInputEvent = (name, stateVariables) => {
  const { optionsButtonsState } = stateVariables;

  const { initialInput, penInput } = optionsButtonsState;

  // if pencil input is selected and then initial input is turned on from off, turn pen mode on
  const toggleButtons =
    !initialInput.selected && !penInput.selected ? [name, 'penInput'] : [name];

  return toggleOptionsButton([toggleButtons], stateVariables);
};

const errorWarningEvent = (name, stateVariables) => {
  const { sudokuBoardState, setSudokuBoardState } = stateVariables;
  const { sudokuNumbers, sudokuWarning } = sudokuBoardState;

  let newWarning;

  if (stateVariables.optionsButtonsState.errorWarning.selected) {
    newWarning = JSON.parse(JSON.stringify(falseBoard));
  } else {
    newWarning = [...sudokuWarning];
    let currentValue;
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        currentValue = sudokuNumbers[i][j];
        !Array.isArray(currentValue) &&
          currentValue !== 0 &&
          (newWarning[i][j] = updateWarningSingleCell(i, j, stateVariables));
      }
    }
  }

  setSudokuBoardState({ ...sudokuBoardState, sudokuWarning: newWarning });
  return toggleOptionsButton([name], stateVariables);
};

const highlightSelectedEvent = (name, stateVariables) => {
  const { sudokuBoardState, focus, setSudokuBoardState } = stateVariables;
  const { sudokuNumbers } = sudokuBoardState;

  let newSameNumber;

  if (stateVariables.optionsButtonsState.highlightSelected.selected) {
    newSameNumber = JSON.parse(JSON.stringify(falseBoard));
  } else {
    newSameNumber = updateSameNumber(focus, sudokuNumbers, stateVariables);
  }

  setSudokuBoardState({ ...sudokuBoardState, sudokuSameNumber: newSameNumber });
  return toggleOptionsButton([name], stateVariables);
};

const crosshairsEvent = (name, stateVariables) => {
  return toggleOptionsButton([name], stateVariables);
};

const arrowScrollOffEvent = (name, stateVariables) => {
  return toggleOptionsButton([name], stateVariables);
};

const autoRemovePencilEvent = (name, stateVariables) => {
  return toggleOptionsButton([name], stateVariables);
};

const rapidFillEvent = (name, stateVariables) => {
  !stateVariables.optionsButtonsState.rapidFill.selectedEvent &&
    resetFocus(stateVariables);

  return toggleOptionsButton([name], stateVariables);
};

const penInputEvent = (name, stateVariables) => {
  const { optionsButtonsState } = stateVariables;

  const { penInput, initialInput } = optionsButtonsState;

  // if switching to pencil mode, turn off initial input if currently on
  const toggleButtons =
    penInput.selected && initialInput.selected
      ? [name, 'initialInput']
      : [name];

  return toggleOptionsButton(toggleButtons, stateVariables);
};

// sudoku cell
const sudokuCellEvent = (e, stateVariables) => {
  const { row: rowString, col: colString } = e.target.dataset;
  const { optionsButtonsState, focus } = stateVariables;

  const row = Number(rowString);
  const col = Number(colString);
  const { initialInput, rapidFill, penInput } = optionsButtonsState;
  const { row: oldRow, col: oldCol, number } = focus;

  switch (true) {
    // if in normal input mode and different cell than current selection is selected move cursor
    case !rapidFill.selected && (row !== oldRow || col !== oldCol):
      return updateFocus(row, col, stateVariables);
    // if in normal input mode and same cell is selected remove cursor
    case !rapidFill.selected && row === oldRow && col === oldCol:
      return resetFocus(stateVariables);
    // if rapid fill mode and initial input, insert negative (initial input) current focus number
    case rapidFill.selected && number !== 0 && initialInput.selected:
      return cellClickRapidFill(row, col, -number, stateVariables);
    // if rapid fill mode and pencil input, insert array (pencil input) current focus number
    case rapidFill.selected && number !== 0 && !penInput.selected:
      return cellClickRapidFill(row, col, [number], stateVariables);
    // if rapid fill mode and pencil input (but not initial input mode), insert current focus number
    case rapidFill.selected &&
      number !== 0 &&
      penInput.selected &&
      !initialInput.selected:
      return cellClickRapidFill(row, col, number, stateVariables);
    default:
      return;
  }
  // if (!rapidFill && (row !== oldRow || col !== oldCol))
  //   return updateFocus(row, col, stateVariables);
  // if (!rapidFill && row === oldRow && col === oldCol)
  //   return resetFocus(stateVariables);
  // if (rapid)
  //   console.log(
  //     'Rapid fill-- make it input current bottom number and then change focus to that cell/number, unless its an original value'
  //   );
};

// bottom number
const bottomNumberEvent = (e, stateVariables) => {
  const { number } = e.target.dataset;
  const { optionsButtonsState, focus } = stateVariables;

  const { initialInput, rapidFill, penInput } = optionsButtonsState;
  const { onBoard, row, col, number: focusNumber } = focus;

  const inputNumber = Number(number);

  switch (true) {
    // if regular entry, cursor on board, and initial input mode is on then input negative number (initial input)
    case !rapidFill.selected && onBoard && initialInput.selected:
      return toggleNumber(row, col, -inputNumber, stateVariables);
    // if regular entry, cursor on board, and pencil mode is on then input array with number (pencil mode)
    case !rapidFill.selected &&
      onBoard &&
      !penInput.selected &&
      !initialInput.selected:
      return toggleNumber(row, col, [inputNumber], stateVariables);
    // if regular entry, cursor on board, pen mode is on, and initial input mode is off then input number
    case !rapidFill.selected &&
      onBoard &&
      penInput.selected &&
      !initialInput.selected:
      return toggleNumber(row, col, inputNumber, stateVariables);
    // if rapid fill and focus number is not input number then update focus number
    case rapidFill.selected && focusNumber !== inputNumber:
      return updateFocusNumber(inputNumber, stateVariables);
    // if rapid fill and focus number is input number then reset focus number
    case rapidFill.selected && focusNumber === inputNumber:
      return resetFocusNumber(stateVariables);
    default:
      break;
  }
};

// gameplay buttons
const startTimerEvent = (name, stateVariables) => {
  const { timer, gameplayButtonsState, setTimer, setGameplayButtonsState } =
    stateVariables;
  setTimer({ ...timer, isActive: !timer.isActive });
  setGameplayButtonsState({
    ...gameplayButtonsState,
    startTimer: {
      ...gameplayButtonsState.startTimer,
      text: timer.isActive ? 'Resume Timer' : 'Pause Timer',
    },
  });
};

const resetTimerEvent = (name, stateVariables) => {
  const { gameplayButtonsState, setTimer, setGameplayButtonsState } =
    stateVariables;
  setTimer(initialTimer);
  setGameplayButtonsState({
    ...gameplayButtonsState,
    startTimer: { ...gameplayButtonsState.startTimer, text: 'Start Timer' },
  });
};

const autofillPencilEvent = (name, stateVariables) => {
  autofillPencil(stateVariables);
};

const newPuzzleEvent = (stateVariables) => {
  const { sudokuBoardState, gameplayButtonsState, setSudokuBoardState } =
    stateVariables;
  const { sudokuNumbers } = sudokuBoardState;
  const { difficulty } = gameplayButtonsState;

  const userConfirmation = arrayEquals(sudokuNumbers, zeroBoard)
    ? true
    : window.confirm('Do you want to reset board with a new puzzle?');

  if (!userConfirmation) return;

  const currentEasyPuzzle = JSON.parse(JSON.stringify(easyPuzzle));
  const currentMediumPuzzle = JSON.parse(JSON.stringify(mediumPuzzle));
  const currentHardPuzzle = JSON.parse(JSON.stringify(hardPuzzle));

  const newSudokuNumbers = [
    currentEasyPuzzle,
    currentMediumPuzzle,
    currentHardPuzzle,
  ][difficulty.selected];
  setSudokuBoardState({
    sudokuNumbers: newSudokuNumbers,
    sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
    sudokuWarning: JSON.parse(JSON.stringify(falseBoard)),
  });
};

const difficultyEvent = (e, stateVariables) => {
  const { index } = e.target.dataset;
  const { gameplayButtonsState, setGameplayButtonsState } = stateVariables;
  const { difficulty } = gameplayButtonsState;

  const previousIndex = difficulty.selected;
  const newIndex = Number(index);

  if (index === previousIndex) return;
  setGameplayButtonsState({
    ...gameplayButtonsState,
    difficulty: { ...difficulty, selected: newIndex },
  });
};

const solveEvent = (stateVariables) => {
  const { optionsButtonsState } = stateVariables;
  const { puzzleMode } = optionsButtonsState;

  if (puzzleMode.selected) {
    console.log('manual solve');
  } else {
    console.log('give solution');
  }
};

const resetInputEvent = (stateVariables) => {
  const deleteInitialInput = checkForNonInitialInput(stateVariables);
  clearOutput(stateVariables, deleteInitialInput);
};

// Action event helpers --------------------------------------------------------
const numberKeyPress = (e, stateVariables) => {
  const { code } = e;
  const { optionsButtonsState, focus } = stateVariables;

  const { initialInput, rapidFill, penInput } = optionsButtonsState;
  const { row, col } = focus;

  // if rapid fill mode on, don't do anything
  if (rapidFill.selected) return;

  // pull number value
  const inputNumber = Number(code.substring(5, 7));

  const outputNumber = initialInput.selected
    ? -inputNumber
    : !penInput.selected // || shift
    ? [inputNumber]
    : inputNumber;

  toggleNumber(row, col, outputNumber, stateVariables);
};

const arrowKeyPress = (e, stateVariables, shift = false) => {
  const { key } = e;
  const { optionsButtonsState, focus, setFocus } = stateVariables;

  const { arrowScrollOff, rapidFill } = optionsButtonsState;
  const { onBoard, row: oldRow, col: oldCol } = focus;

  // don't scroll if Arrow Scroll Off button is selected
  arrowScrollOff.selected && e.preventDefault();

  // if Rapid Fill mode is on, don't put the cursor on
  if (rapidFill.selected) return;

  // put cursor on board if it isn't already
  if (!onBoard) return setFocus({ ...focus, onBoard: true });

  // move cursor and update focus number as dictated below
  switch (true) {
    case shift && key === 'ArrowRight':
      return updateFocus(oldRow, oldCol === 8 ? 0 : 8, stateVariables);
    case shift && key === 'ArrowLeft':
      return updateFocus(oldRow, oldCol === 0 ? 8 : 0, stateVariables);
    case shift && key === 'ArrowUp':
      return updateFocus(oldRow === 0 ? 8 : 0, oldCol, stateVariables);
    case shift && key === 'ArrowDown':
      return updateFocus(oldRow === 8 ? 0 : 8, oldCol, stateVariables);
    case key === 'ArrowRight':
      return updateFocus(oldRow, (9 + oldCol + 1) % 9, stateVariables);
    case key === 'ArrowLeft':
      return updateFocus(oldRow, (9 + oldCol - 1) % 9, stateVariables);
    case key === 'ArrowUp':
      return updateFocus((9 + oldRow - 1) % 9, oldCol, stateVariables);
    case key === 'ArrowDown':
      return updateFocus((9 + oldRow + 1) % 9, oldCol, stateVariables);
    default:
      break;
  }
};

const shiftKeyToggle = (stateVariables, pressDown) => {
  const {
    optionsButtonsState,
    shiftKeyWasPen,
    setOptionsButtonsState,
    setShiftKeyWasPen,
  } = stateVariables;

  const { initialInput, rapidFill, penInput } = optionsButtonsState;

  // exit if in initial input or rapid fill mode
  if (initialInput.selected || rapidFill.selected) return;

  switch (true) {
    // if shift pressed and in pen mode
    case pressDown && penInput.selected:
      setShiftKeyWasPen(true);
      return setOptionsButtonsState({
        ...optionsButtonsState,
        penInput: { ...penInput, selected: false },
      });
    // if shift pressed and not in pen mode
    case pressDown && !penInput.selected:
      return setShiftKeyWasPen(false);
    // if shift released
    case !pressDown && shiftKeyWasPen:
      return setOptionsButtonsState({
        ...optionsButtonsState,
        penInput: { ...penInput, selected: true },
      });
    default:
      break;
  }
};

const backspaceKeyPress = (stateVariables, shift = false) => {
  // TODO consider having shift + backspace be able to do something
  const { focus, setFocus } = stateVariables;

  const { row, col } = focus;

  toggleNumber(row, col, 0, stateVariables);
  setFocus({ ...focus, number: 0 });
};

const cellClickRapidFill = (row, col, number, stateVariables) => {
  const newFocus = updateFocus(row, col, stateVariables);
  const { focus: newerFocus, sudokuBoardState: newsudokuBoardState } =
    updateFocusNumber(number, { ...stateVariables, focus: newFocus });
  toggleNumber(row, col, number, {
    ...stateVariables,
    sudokuBoardState: newsudokuBoardState,
    focus: newerFocus,
  });
};

// General helper functions ----------------------------------------------------
const autofillPencil = (stateVariables) => {
  const { optionsButtonsState, sudokuBoardState, focus, setSudokuBoardState } =
    stateVariables;

  const { highlightSelected } = optionsButtonsState;
  const { sudokuNumbers, sudokuSameNumber } = sudokuBoardState;

  let newSudokuNumbers = JSON.parse(JSON.stringify(sudokuNumbers));

  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      // move to next value if value is non-zero and non-array
      if (
        !(newSudokuNumbers[i][j] === 0) &&
        !Array.isArray(newSudokuNumbers[i][j])
      )
        continue;
      newSudokuNumbers[i][j] = missingRowColBoxValues(i, j, stateVariables);
    }
  }

  const newSameNumber = highlightSelected
    ? updateSameNumber(focus, newSudokuNumbers, stateVariables)
    : sudokuSameNumber;

  setSudokuBoardState({
    ...sudokuBoardState,
    sudokuNumbers: newSudokuNumbers,
    sudokuSameNumber: newSameNumber,
  });
};

const toggleNumber = (row, col, newValue, stateVariables) => {
  const {
    optionsButtonsState,
    sudokuBoardState,
    focus,
    setSudokuBoardState,
    setFocus,
  } = stateVariables;

  const {
    initialInput,
    errorWarning,
    highlightSelected,
    autoRemovePencil,
    rapidFill,
  } = optionsButtonsState;
  const { sudokuNumbers, sudokuSameNumber, sudokuWarning } = sudokuBoardState;

  console.log(focus);

  let newSudokuNumbers = [...sudokuNumbers];
  let newSudokuSameNumber = [...sudokuSameNumber];
  let newSudokuWarning = [...sudokuWarning];

  let newFocus = { ...focus };

  const oldValue = sudokuNumbers[row][col];

  switch (true) {
    // if new value is 0 and old value is not less than 0 or initial input mode is on set out value to 0
    case newValue === 0 && oldValue < 0 && !initialInput.selected:
      break;
    // otherwise if new value = 0 set out value to 0
    case newValue === 0:
      newSudokuNumbers[row][col] = newValue;
      !rapidFill.selected && (newFocus.number = newValue);
      break;
    // if new value and old value are equal, toggle to 0
    case newValue === oldValue:
      newSudokuNumbers[row][col] = 0;
      !rapidFill.selected && (newFocus.number = 0);
      // setFocus({ ...focus, number: 0 });
      break;
    // if old value is 0, insert new value
    case oldValue === 0:
      newSudokuNumbers[row][col] = newValue;
      !rapidFill.selected && (newFocus.number = newValue);
      // setFocus({ ...focus, number: newValue });
      break;
    // if the old and new values are both arrays (penicl), insert the new value if it isn't already there or remove it if it is already there
    case Array.isArray(oldValue) && Array.isArray(newValue):
      const valueIndex = oldValue.indexOf(newValue[0]);
      valueIndex !== -1
        ? oldValue.splice(valueIndex, 1)
        : oldValue.push(newValue[0]);
      newSudokuNumbers[row][col] = oldValue;
      break;
    // if the old value is less than 0 (initial input) then don't change unless the input value is also less than 0 (initial input; will go to the default case)
    case oldValue < 0 && (Array.isArray(newValue) || newValue > 0):
      break;
    // otherwise change to the new values ()
    default:
      newSudokuNumbers[row][col] = newValue;
      !rapidFill.selected &&
        (newFocus.number = Array.isArray(newValue) ? newValue[0] : newValue);
      break;
  }

  // change first values below (function)
  newSudokuWarning = errorWarning.selected
    ? updateWarning(
        row,
        col,
        oldValue,
        newSudokuNumbers[row][col],
        stateVariables
      )
    : newSudokuWarning;

  // change pencil numbers as needed
  newSudokuNumbers = autoRemovePencil.selected
    ? updatePencilNumbers(
        newSudokuNumbers,
        row,
        col,
        newSudokuNumbers[row][col],
        stateVariables
      )
    : newSudokuNumbers;
  // change first values below (function)
  newSudokuSameNumber = highlightSelected.selected
    ? updateSameNumber(newFocus, newSudokuNumbers, stateVariables)
    : newSudokuSameNumber;

  setSudokuBoardState({
    sudokuNumbers: newSudokuNumbers,
    sudokuSameNumber: newSudokuSameNumber,
    sudokuWarning: newSudokuWarning,
  });
  setFocus(newFocus);
};

const updateSameNumber = (newFocus, newSudokuNumbers, stateVariables) => {
  const { number: newFocusNumber } = newFocus;
  // const { sudokuBoardState } = stateVariables;

  // const { sudokuSameNumber } = sudokuBoardState;

  // if new focus number is 0, return falseboard
  if (newFocusNumber === 0) return JSON.parse(JSON.stringify(falseBoard));

  let newSudokuSameNumber = JSON.parse(JSON.stringify(falseBoard));

  newSudokuNumbers.forEach((row, rowIndex) => {
    row.forEach((value, colIndex) => {
      newSudokuSameNumber[rowIndex][colIndex] = Array.isArray(value)
        ? value.includes(newFocusNumber)
        : Math.abs(value) === newFocusNumber;
    });
  });

  return newSudokuSameNumber;
};

const updateWarning = (row, col, oldValue, newValue, stateVariables) => {
  const rowColBox = rowColBoxValues(row, col, stateVariables, true, true, true);
  const { sudokuBoardState } = stateVariables;

  const { sudokuWarning } = sudokuBoardState;

  let newWarning = [...sudokuWarning];

  const { row: rowValues, col: colValues, box: boxValues } = rowColBox;

  let alreadyDone = JSON.parse(JSON.stringify(falseBoard));

  let currentRow;
  let currentCol;

  const boxRow = Math.floor(row / 3);
  const boxCol = Math.floor(col / 3);
  let currentBoxActRow;
  let currentBoxActCol;

  for (let i = 0; i < 9; i++) {
    if (
      !alreadyDone[row][i] &&
      rowValues[i] !== 0 &&
      (rowValues[i] === oldValue || rowValues[i] === newValue)
    ) {
      currentRow = row;
      currentCol = i;
      newWarning[currentRow][currentCol] = updateWarningSingleCell(
        currentRow,
        currentCol,
        stateVariables
      );
      alreadyDone[currentRow][currentCol] = true;
    }
    if (
      !alreadyDone[i][col] &&
      colValues[i] !== 0 &&
      (colValues[i] === oldValue || colValues[i] === newValue)
    ) {
      currentRow = i;
      currentCol = col;
      newWarning[currentRow][currentCol] = updateWarningSingleCell(
        currentRow,
        currentCol,
        stateVariables
      );
      alreadyDone[currentRow][currentCol] = true;
    }
    currentBoxActRow = boxRow * 3 + Math.floor(i / 3);
    currentBoxActCol = boxCol * 3 + (i % 3);
    if (
      !alreadyDone[currentBoxActRow][currentBoxActCol] &&
      boxValues[i] !== 0 &&
      (boxValues[i] === oldValue || boxValues[i] === newValue)
    ) {
      currentRow = currentBoxActRow;
      currentCol = currentBoxActCol;
      newWarning[currentRow][currentCol] = updateWarningSingleCell(
        currentRow,
        currentCol,
        stateVariables
      );
      alreadyDone[currentRow][currentCol] = true;
    }
  }

  newWarning[row][col] = updateWarningSingleCell(row, col, stateVariables);

  return newWarning;
};

const updatePencilNumbers = (
  sudokuNumbers,
  row,
  col,
  newValue,
  stateVariables
) => {
  // exit function if new value in array or 0
  if (Array.isArray(newValue) || newValue === 0) return sudokuNumbers;

  const absNewValue = Math.abs(newValue);

  const rowColBox = rowColBoxValues(
    row,
    col,
    stateVariables,
    false,
    false,
    false,
    true
  );

  let newSudokuNumbers = JSON.parse(JSON.stringify(sudokuNumbers));

  const { row: rowValues, col: colValues, box: boxValues } = rowColBox;

  let alreadyDone = JSON.parse(JSON.stringify(falseBoard));
  alreadyDone[row][col] = true;

  let currentRow;
  let currentCol;
  let currentValue;

  const boxRow = Math.floor(row / 3);
  const boxCol = Math.floor(col / 3);
  let currentBoxActRow;
  let currentBoxActCol;

  for (let i = 0; i < 9; i++) {
    if (!alreadyDone[row][i] && rowValues[i] !== 0) {
      currentRow = row;
      currentCol = i;
      currentValue = newSudokuNumbers[currentRow][currentCol];
      newSudokuNumbers[currentRow][currentCol] = removeValueIfInArray(
        currentValue,
        absNewValue
      );
      alreadyDone[currentRow][currentCol] = true;
    }
    if (!alreadyDone[i][col] && colValues[i] !== 0) {
      currentRow = i;
      currentCol = col;
      currentValue = newSudokuNumbers[currentRow][currentCol];
      newSudokuNumbers[currentRow][currentCol] = removeValueIfInArray(
        currentValue,
        absNewValue
      );
      alreadyDone[currentRow][currentCol] = true;
    }
    currentBoxActRow = boxRow * 3 + Math.floor(i / 3);
    currentBoxActCol = boxCol * 3 + (i % 3);
    if (
      !alreadyDone[currentBoxActRow][currentBoxActCol] &&
      boxValues[i] !== 0
    ) {
      currentRow = currentBoxActRow;
      currentCol = currentBoxActCol;
      currentValue = newSudokuNumbers[currentRow][currentCol];
      newSudokuNumbers[currentRow][currentCol] = removeValueIfInArray(
        currentValue,
        absNewValue
      );
      alreadyDone[currentRow][currentCol] = true;
    }
  }
  return newSudokuNumbers;
};

const updateFocus = (row, col, stateVariables) => {
  const {
    optionsButtonsState,
    sudokuBoardState,
    setSudokuBoardState,
    setFocus,
  } = stateVariables;

  const { sudokuNumbers } = sudokuBoardState;

  const newFocus = {
    onBoard: true,
    number: Array.isArray(sudokuNumbers[row][col])
      ? 0
      : Math.abs(sudokuNumbers[row][col]),
    row: row,
    col: col,
  };

  optionsButtonsState.highlightSelected.selected &&
    setSudokuBoardState({
      ...stateVariables.sudokuBoardState,
      sudokuSameNumber: updateSameNumber(
        newFocus,
        stateVariables.sudokuBoardState.sudokuNumbers,
        stateVariables
      ),
    });
  // updateSameNumber(sudokuBoardState, newFocus, setSudokuBoardState);
  setFocus(newFocus);
  return newFocus;
};

const resetFocus = (stateVariables) => {
  const {
    optionsButtonsState,
    sudokuBoardState,
    setSudokuBoardState,
    setFocus,
  } = stateVariables;

  const { highlightSelected } = optionsButtonsState;

  highlightSelected.selected &&
    setSudokuBoardState({
      ...sudokuBoardState,
      sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
    });

  setFocus(initialFocus);
  setSudokuBoardState({
    ...stateVariables.sudokuBoardState,
    sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
  });
};

const updateFocusNumber = (inputNumber, stateVariables) => {
  const {
    optionsButtonsState,
    sudokuBoardState,
    focus,
    setSudokuBoardState,
    setFocus,
  } = stateVariables;

  const { highlightSelected } = optionsButtonsState;

  let newNumber = Array.isArray(inputNumber)
    ? inputNumber[0]
    : Math.abs(inputNumber);

  setFocus({ ...focus, number: newNumber });
  highlightSelected.selected &&
    setSudokuBoardState({
      ...stateVariables.sudokuBoardState,
      sudokuSameNumber: updateSameNumber(
        { ...focus, number: newNumber },
        stateVariables.sudokuBoardState.sudokuNumbers,
        stateVariables
      ),
    });
  return {
    focus: { ...focus, number: newNumber },
    sudokuBoardState: {
      ...stateVariables.sudokuBoardState,
      sudokuSameNumber: highlightSelected.selected
        ? updateSameNumber(
            { ...focus, number: newNumber },
            stateVariables.sudokuBoardState.sudokuNumbers,
            stateVariables
          )
        : sudokuBoardState,
    },
  };
};

const resetFocusNumber = (stateVariables) => {
  const {
    optionsButtonsState,
    sudokuBoardState,
    focus,
    setSudokuBoardState,
    setFocus,
  } = stateVariables;

  const { highlightSelected } = optionsButtonsState;

  highlightSelected.selected &&
    setSudokuBoardState({
      ...sudokuBoardState,
      sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
    });

  setFocus({ ...focus, number: 0 });
  setSudokuBoardState({
    ...stateVariables.sudokuBoardState,
    sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
  });
};

const toggleOptionsButton = (names, stateVariables) => {
  const { optionsButtonsState, setOptionsButtonsState } = stateVariables;
  let newOptionsButtonsState = { ...optionsButtonsState };

  names.forEach((name) => {
    const currentState = optionsButtonsState[name];
    const currentSelected = currentState.selected;
    newOptionsButtonsState = {
      ...newOptionsButtonsState,
      [name]: { ...currentState, selected: !currentSelected },
    };
  });

  setOptionsButtonsState(newOptionsButtonsState);
};

const clearOutput = (stateVariables, deleteInitialInput = false) => {
  const { sudokuBoardState, setSudokuBoardState } = stateVariables;
  const { sudokuNumbers } = sudokuBoardState;

  let newSudokuNumbers = JSON.parse(JSON.stringify(sudokuNumbers));

  newSudokuNumbers.forEach((row, rowIndex) =>
    row.forEach(
      (val, colIndex) =>
        (newSudokuNumbers[rowIndex][colIndex] =
          val < 0 && deleteInitialInput
            ? newSudokuNumbers[rowIndex][colIndex]
            : 0)
    )
  );

  setSudokuBoardState({
    sudokuNumbers: newSudokuNumbers,
    sudokuSameNumber: JSON.parse(JSON.stringify(falseBoard)),
    sudokuWarning: JSON.parse(JSON.stringify(falseBoard)),
  });
};

const checkForNonInitialInput = (stateVariables) => {
  // should be false then true; checkfor... should be true if there is more than just negative values; false if it is only negative values and 0s
  const { sudokuBoardState } = stateVariables;
  const { sudokuNumbers } = sudokuBoardState;

  let noninitialInputOnBoard = false;

  sudokuNumbers.forEach((row) =>
    row.forEach(
      (val) => !(val < 0 || val === 0) && (noninitialInputOnBoard = true)
    )
  );

  return noninitialInputOnBoard;
};

// ----- helper helper functions -----

const rowColBoxValues = (
  inputRow,
  inputCol,
  stateVariables,
  removeArrays = false,
  absoluteValue = false,
  excludeSelf = false,
  onlyArrays = false
) => {
  const { sudokuBoardState } = stateVariables;

  const { sudokuNumbers } = sudokuBoardState;

  // put the current row, column, and box in their own respective arrays
  let rowValues = sudokuNumbers.filter((val, index) => index === inputRow)[0];
  let colValues = sudokuNumbers.map((val, index) => val[inputCol]);
  let boxValues = sudokuNumbers
    .filter(
      (row, rowIndex) => Math.floor(rowIndex / 3) === Math.floor(inputRow / 3)
    )
    .map((rowCol, rowIndex) => {
      const colIndexReference = Math.floor(inputCol / 3);
      return rowCol.slice(colIndexReference * 3, (colIndexReference + 1) * 3);
    });

  boxValues = boxValues.reduce((prev, curr) => prev.concat(curr), []);

  if (removeArrays) {
    rowValues = rowValues.map((val) => (Array.isArray(val) ? 0 : val));
    colValues = colValues.map((val) => (Array.isArray(val) ? 0 : val));
    boxValues = boxValues.map((val) => (Array.isArray(val) ? 0 : val));
  }

  if (absoluteValue) {
    rowValues = rowValues.map((val) =>
      Array.isArray(val) ? val : Math.abs(val)
    );
    colValues = colValues.map((val) =>
      Array.isArray(val) ? val : Math.abs(val)
    );
    boxValues = boxValues.map((val) =>
      Array.isArray(val) ? val : Math.abs(val)
    );
  }

  if (excludeSelf) {
    rowValues[inputCol] = 0;
    colValues[inputRow] = 0;
    boxValues[(inputRow % 3) * 3 + (inputCol % 3)] = 0;
  }

  if (onlyArrays) {
    rowValues = rowValues.map((val) => (Array.isArray(val) ? val : 0));
    colValues = colValues.map((val) => (Array.isArray(val) ? val : 0));
    boxValues = boxValues.map((val) => (Array.isArray(val) ? val : 0));
  }

  const out = {
    row: rowValues,
    col: colValues,
    box: boxValues,
  };

  return out;
};

const uniqueRowColBoxValues = (inputRow, inputCol, stateVariables) => {
  const valuesArrays = rowColBoxValues(
    inputRow,
    inputCol,
    stateVariables,
    true,
    true,
    true,
    false
  );
  const valuesSingleArray = [
    ...valuesArrays.row,
    ...valuesArrays.col,
    ...valuesArrays.box,
  ];
  const out = [...new Set(valuesSingleArray)];

  return out;
};

const missingRowColBoxValues = (inputRow, inputCol, stateVariables) => {
  const uniqueRowColBox = uniqueRowColBoxValues(
    inputRow,
    inputCol,
    stateVariables
  );

  let out = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  uniqueRowColBox.forEach((val) => (out = removeValueIfInArray(out, val)));
  return out;
};

const updateWarningSingleCell = (row, col, stateVariables) => {
  const { sudokuBoardState } = stateVariables;

  const { sudokuNumbers } = sudokuBoardState;

  const currentValue = Array.isArray(sudokuNumbers[row][col])
    ? sudokuNumbers[row][col]
    : Math.abs(sudokuNumbers[row][col]);

  if (Array.isArray(currentValue) || currentValue === 0) return false;

  const uniqueRowColBox = uniqueRowColBoxValues(row, col, stateVariables);

  return uniqueRowColBox.includes(currentValue);
};

const removeValueIfInArray = (array, value) => {
  const index = array.indexOf(value);
  index > -1 && array.splice(index, 1);
  return array;
};

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) =>
      Array.isArray(val) && Array.isArray(b[index])
        ? arrayEquals(val, b[index])
        : val === b[index]
    )
  );
}

// const duplicateIndices = (arr) => {
//   const uniqueArr = [...new Set(arr)];
//   let indices = [];

//   uniqueArr.forEach((val) => {
//     if (val === 0) return;
//     const tempIndex = [];
//     arr.forEach((arrayVal, index) => {
//       arrayVal === val && tempIndex.push(index);
//     });
//     indices.push(tempIndex);
//   });

// return indices;
// };

// General helper constants ----------------------------------------------------

// ----- initial state values -----

const createBoard = (value, rows = 9, cols = 9) => {
  const row = new Array(cols).fill(value);
  const out = new Array(rows).fill(row);
  return out;
};

export const zeroBoard = createBoard(0);
// [
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 0, 0],
// ];

export const falseBoard = createBoard(false);
// [
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
//   [false, false, false, false, false, false, false, false, false],
// ];

export const initialOptionsButtons = {
  puzzleMode: { selected: true, visible: true },
  initialInput: { selected: false, visible: true },
  errorWarning: { selected: false, visible: true },
  highlightSelected: { selected: false, visible: true },
  crosshairs: { selected: false, visible: true },
  arrowScrollOff: { selected: true, visible: true },
  autoRemovePencil: { selected: false, visible: true },
  rapidFill: { selected: false, visible: true },
  penInput: { selected: true, visible: true },
};
export const initialFocus = { onBoard: false, row: 0, col: 0, number: 0 };
export const initialTimer = { isActive: false, seconds: 0 };
// export const initialGameplayText = {
//   startTimer: ['Start Timer'],
//   resetTimer: ['Reset Timer'],
//   autofillPencil: ['Autofill Pencil'],
//   difficulty: ['Ez', 'Med', 'Hard'],
//   resetInput: ['Reset Input'],
// };
export const initialGameplayButtons = {
  startTimer: {
    name: 'startTimer',
    text: ['Start Timer'],
    visible: true,
    selected: null,
  },
  resetTimer: {
    name: 'resetTimer',
    text: ['Reset Timer'],
    visible: true,
    selected: null,
  },
  autofillPencil: {
    name: 'autofillPencil',
    text: ['Autofill Pencil'],
    visible: true,
    selected: null,
  },
  newPuzzle: {
    name: 'difficulty',
    text: ['New Puzzle'],
    visible: false,
    selected: null,
  },
  difficulty: {
    name: 'difficulty',
    text: ['Ez', 'Med', 'Hard'],
    visible: false,
    selected: 0,
  },
  solve: {
    name: 'solve',
    text: ['Attempt Solve'],
    visible: true,
    selected: 0,
  },
  resetInput: {
    name: 'resetInput',
    text: ['Reset Input'],
    visible: true,
    selected: null,
  },
};

const easyPuzzle = [
  [0, 0, -3, -9, -8, -7, -4, 0, 0],
  [0, -7, 0, 0, -4, 0, 0, -5, 0],
  [-2, 0, 0, 0, -3, 0, 0, 0, -7],
  [-5, -6, 0, -1, 0, -4, 0, -7, -3],
  [0, 0, 0, -7, 0, -6, 0, 0, 0],
  [-9, -2, 0, -3, 0, -8, 0, -6, -4],
  [-1, 0, 0, 0, -6, 0, 0, 0, -8],
  [0, -8, 0, 0, -7, 0, 0, -4, 0],
  [0, 0, -6, -8, -1, -3, -9, 0, 0],
];

const mediumPuzzle = easyPuzzle;
const hardPuzzle = easyPuzzle;
