import React from 'react';
import Moment from 'react-moment';

export const PortfolioLinksCard = ({ href, iconClass, title, description }) => (
  <a class='portfolio-links-card col-sm-12 col-lg-3 col-xlg-3' href={href}>
    <div class='portfolio-links-title-container'>
      <i class={iconClass}></i>
      <p class='small portfolio-links-title'>{title}</p>
    </div>
    <p class='x-small'>{description}</p>
  </a>
);

export const ProjectsFilterSelector = ({ text, selected, name, onClick }) => {
  selected = selected === 'true';
  return (
    <p
      className={`text-white ${selected ? 'selected' : ''}`}
      onClick={(e) => onClick(e)}
      data-name={name}
    >
      {text}
    </p>
  );
};

export const ProjectsFilterPill = ({
  text,
  name,
  includeX = 'false',
  onClick = null,
}) => (
  <div className='btn btn-primary selected-tag-filter'>
    {text}{' '}
    {includeX === 'true' && (
      <>
        &nbsp; &nbsp;
        <i
          className='far fa-times-circle delete-tag-filter'
          onClick={onClick && ((e) => onClick(e))}
          data-name={name}
        />
      </>
    )}
  </div>
);

export const ProjectCard = ({ project }) => {
  const { title, date, imgReference, imgAltText, tags, description, href } =
    project;
  return (
    <a className='project-card col-lg-3 col-xlg-2' href={href}>
      <p className='small project-card-title'>{title}</p>
      <div class='project-card-img-container'>
        <div class='img-spacer'></div>
        <img src={imgReference} alt={imgAltText} />
      </div>
      <p className='x-small text-white project-card-date'>
        <Moment format='MMM YYYY'>{date}</Moment>
      </p>
      <div className='project-card-description-container'>
        <p className='x-small text-light project-card-description'>
          {description}
        </p>
      </div>
      <div className='selected-tag-filter-container'>
        {tags.map((tag, index) => (
          <ProjectsFilterPill key={index} text={tag} />
        ))}
      </div>
    </a>
  );
};

// Art container components
export const ArtContainer = ({ title, explanation, children }) => (
  <>
    <h1 className='large text-primary'>{title}</h1>
    <p className='lead'>{explanation}</p>
    <div className='art-container'>{children}</div>
  </>
);
