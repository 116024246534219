import React, { useState } from 'react';
import businessResumePDF from '../../img/Jeff Griffiths Resume 1.pdf';
import technicalResumePDF from '../../img/Jeff Griffiths Resume 2.pdf'; // TO DO need to change PDF
import {
  // BusinessResumeContactHeaders,
  // BusinessResumeDescription,
  // BusinessResumeEducation,
  // BusinessResumeExperience,
  // BusinessResumeSectionHeader,
  ResumeSwitch,
} from './Resume.components';
import BusinessResume from './ResumeBusiness';
import TechnicalResume from './ResumeTechnical';
// import {
//   contactData,
//   experienceData,
//   educationData,
//   leadershipData,
//   skillsAndInterestsData,
// } from './Resume.data';

const Resume = () => {
  const [brSelected, setBrSelected] = useState(() => false);

  const handleClick = (event) => {
    const clickedElement = event.target;
    const brClicked = clickedElement.classList.contains('br-switch');
    const trClicked = clickedElement.classList.contains('tr-switch');

    const shouldSetBrSelected =
      (brClicked && !brSelected) || (trClicked && brSelected);

    shouldSetBrSelected && setBrSelected((prevState) => !prevState);
    // (
    //   (brClicked && !brSelected) || (trClicked && brSelected)
    // ) && setBrSelected((prevState) => !prevState);
  };

  // const brSelected = false;
  const firstClassGroup = `section-container${
    brSelected ? ' business-resume' : ''
  }`;
  const secondClassGroup = `resume-container${
    brSelected ? '' : ' grid technical-resume'
  }`;

  return (
    <div className='content-container resume-page'>
      <div className='section-light'>
        <div className={firstClassGroup}>
          <ResumeSwitch handleClick={handleClick} brSelected={brSelected} />
          <div className={secondClassGroup}>
            {/* <TechnicalResume /> */}
            {brSelected ? <BusinessResume /> : <TechnicalResume />}
          </div>

          {/* Download Button */}
          <div className='button-container'>
            <a
              className='btn btn-primary download-resume'
              type='button'
              href={brSelected ? businessResumePDF : technicalResumePDF}
              download
            >
              <span className='fas fa-download'></span> Download Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
