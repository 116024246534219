import React from 'react';

export const RainbowSpiralSingle = ({ iteration, children }) => {
  const classGroupOne = ['rs-ws-1', 'rs-hs-1', 'rs-ws-2', 'rs-hs-2'];
  const classNames = `${classGroupOne[iteration % 4]} + ' rs-c${
    (iteration % 8) + 1
  }`;

  return <div className={classNames}>{children}</div>;
};

export const RainbowSpiralAll = ({ layers, iteration = 0 }) =>
  iteration === layers ? (
    <></>
  ) : (
    <RainbowSpiralSingle iteration={iteration}>
      <RainbowSpiralAll layers={layers} iteration={iteration + 1} />
    </RainbowSpiralSingle>
  );
