import React from 'react';

const Error = () => {
  return (
    <div className='content-container error-page'>
      <div className='section-light'>
        <div className='section-container'>
          <h1 className='x-large text-primary px-3'>404 ERROR</h1>
          <div className='bg-dark bg-dark p-3 my-3'>
            <h2 className='medium text-light '>
              Great area code, not a great status code...
            </h2>
          </div>
          <div className='px-3'>
            <a href='/' className='small text-dark'>
              Return Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
