import React from 'react';
import { AboutCard } from './About.components';
import { aboutData } from './About.data';

const About = () => {
  return (
    <div className='content-container about-page'>
      {aboutData.map((about, index) => {
        return <AboutCard key={index} order={index} about={about} />;
      })}
    </div>
  );
};

export default About;
