import React, { Fragment } from 'react';

// Options Buttons
export const SudokuOptionsButton = ({ buttonInfo, onClick, selected }) => {
  const { type = 'single', text, name } = buttonInfo;
  // type !== 'single' && console.log('options');
  // type !== 'single' &&
  //   console.log(
  //     `selected: ${selected} name: ${name} onClick: ${onClick} text: ${text}`
  //   );
  return type === 'single' ? (
    <button
      className={`btn options-btn ${selected ? 'btn-dark' : 'btn-white'}`}
      onClick={(e) => onClick(e)}
      data-name={name}
    >
      {text[0]}
    </button>
  ) : (
    <MultiButton
      selected={selected}
      name={name}
      onClick={onClick}
      text={text}
      selectedColor='btn-dark'
    />
  );
};

// Sudoku board
export const SudokuBoard = ({
  sudokuBoardState,
  focus,
  optionsButtons,
  onCellClick,
}) => {
  const { sudokuNumbers, sudokuSameNumber, sudokuWarning } = sudokuBoardState;

  var rows = [];
  for (let i = 0; i < 9; i++) {
    var rowsContent = [];
    for (let j = 0; j < 9; j++) {
      // const numberValue = i * 3 + j + 1;

      const numberValue = sudokuNumbers[i][j];
      let cellContents = ' ';
      let additionalClasses = '';

      if (Array.isArray(numberValue)) {
        cellContents = (
          <SudokuPencil
            pencilArray={numberValue}
            onClick={onCellClick}
            row={i}
            col={j}
          />
        );
        // } else if (numberValue === 0) {
        //   cellContents = '';
        //   additionalClasses = '';
      } else if (numberValue < 0) {
        cellContents = Math.abs(numberValue);
        additionalClasses = 'sudoku-initial-cell';
      } else if (numberValue > 0) {
        cellContents = numberValue;
      }

      // Add cursor class if needed
      focus.onBoard &&
        focus.row === i &&
        focus.col === j &&
        (additionalClasses += ' sudoku-cell-selected');
      // numberValue >= 0 || Array.isArray(numberValue)
      //   ? ' sudoku-cell-selected'
      //   : ' sudoku-cell-selected-initial-input');

      // Add crosshairs class if needed
      const sameRowOrCol = focus.row === i || focus.col === j;
      const sameBox =
        Math.floor(focus.row / 3) === Math.floor(i / 3) &&
        Math.floor(focus.col / 3) === Math.floor(j / 3);
      focus.onBoard &&
        optionsButtons.crosshairs.selected &&
        !(focus.row === i && focus.col === j) &&
        (sameRowOrCol || sameBox) &&
        (additionalClasses += ' sudoku-crosshairs');

      // Add highlighted number class if needed
      sudokuSameNumber[i][j] && (additionalClasses += ' sudoku-same-number');

      // // Add error class if needed
      sudokuWarning[i][j] && (additionalClasses += ' sudoku-error');
      // numberValue >= 0 ? ' sudoku-error' : ' sudoku-error-initial-input');

      rowsContent.push({
        row: i,
        col: j,
        cellContents: cellContents,
        additionalClasses: additionalClasses,
      });
    }
    rows.push(
      <tr key={i} className='sudoku-table-row'>
        {rowsContent.map(
          ({ row, col, cellContents, additionalClasses }, index) => (
            <SudokuCell
              key={index}
              row={row}
              col={col}
              cellContents={cellContents}
              additionalClasses={additionalClasses}
              onClick={onCellClick}
            />
          )
        )}
      </tr>
    );
  }

  return (
    <div className='sudoku-inner-container'>
      <table className='sudoku-table'>
        <tbody>{rows}</tbody>
      </table>
    </div>
    // {/* <table className='sudoku-pencil'>
    //   <tbody>{rows}</tbody>
    // </table> */}
  );
};

export const SudokuCell = ({
  row,
  col,
  cellContents,
  additionalClasses = '',
  onClick,
}) => {
  const colGroup = col % 3;
  const rowGroup = row % 3;
  const colThirds = Math.floor(col / 3);
  const rowThirds = Math.floor(row / 3);
  const right = colGroup === 2 && col !== 8 ? 'sudoku-right' : '';
  const bottom = rowGroup === 2 && row !== 8 ? 'sudoku-bottom' : '';
  const light =
    colThirds % 2 === rowThirds % 2 ? 'sudoku-dark' : 'sudoku-light';
  return (
    <td
      className={`sudoku-cell ${right} ${bottom} ${light} ${additionalClasses}`}
      data-name='sudokuCell'
      data-row={row}
      data-col={col}
      data-id={`r${row}-c${col}-cell`}
      onClick={(e) => onClick(e)}
    >
      {cellContents}
    </td>
  );
};

export const SudokuPencil = ({ pencilArray, onClick, row, col }) => {
  var rows = [];
  for (var i = 0; i < 3; i++) {
    var rowsContent = [];
    for (var j = 0; j < 3; j++) {
      const numberValue = i * 3 + j + 1;
      rowsContent.push({
        numberValue: numberValue,
        hidden: !pencilArray.includes(numberValue),
      });
    }
    rows.push(
      <tr key={i}>
        {rowsContent.map(({ numberValue, hidden }, index) => (
          <td
            key={index}
            className={hidden ? 'sudoku-pencil-hide' : undefined}
            //onClick={(e) => onClick(e)}
            data-name='sudokuCell'
            data-row={row}
            data-col={col}
            data-id={`r${row}-c${col}-pencil`}
          >
            {numberValue}
          </td>
        ))}
      </tr>
    );
  }

  return (
    <table className='sudoku-pencil'>
      <tbody>{rows}</tbody>
    </table>
  );
};

// Bottom numbers
export const SudokuBottomNumbers = ({ bottomNumber, rapidFill, onClick }) => {
  let out = [];
  for (let i = 1; i <= 9; i++) {
    out.push(
      <SudokuBottomCell
        key={i}
        number={i}
        selected={rapidFill && i === bottomNumber}
        onClick={onClick}
      />
    );
  }
  return (
    <div className='bottom-number-div'>
      <table className='bottom-number-table'>
        <tbody>
          <tr>{out}</tr>
        </tbody>
      </table>
    </div>
  );
};

export const SudokuBottomCell = ({ number, selected, onClick }) => (
  <td className='bottom-number-container'>
    <div
      className={`bottom-number ${selected ? 'bottom-number-selected' : ''}`}
      onClick={(e) => onClick(e)}
      data-name='bottomNumber'
      data-number={number}
    >
      {number}
    </div>
  </td>
);

// Gameplay buttons
export const SudokuTimer = ({ timer }) => {
  const { seconds: totalSeconds } = timer;
  const secInMin = 60;
  const minInHr = 60;

  const makeString = (val) =>
    Math.floor(val / 10) > 0 ? val.toString() : '0' + val.toString();

  const seconds = totalSeconds % secInMin;
  const minutes = Math.floor(totalSeconds / secInMin) % minInHr;
  const hours = Math.floor(totalSeconds / (secInMin * minInHr));

  return (
    <p>{`${makeString(hours)}:${makeString(minutes)}:${makeString(
      seconds
    )}`}</p>
  );
}; //00:00:00

export const SudokuGameplayButton = ({ buttonInfo, buttonState, onClick }) => {
  const { name, buttonType, lineBelow, type } = buttonInfo;
  const gameplayState = buttonState[name];
  const { text, visible, selected } = gameplayState;

  // type !== 'single' && console.log('gameplay');
  // type !== 'single' &&
  //   console.log(
  //     `selected: ${selected} name: ${name} onClick: ${onClick} text: ${text}`
  //   );

  return visible && name !== 'solve' ? (
    <>
      {type === 'single' ? (
        <button
          className={`btn options-btn ${buttonType}`}
          data-name={name}
          onClick={(e) => onClick(e)}
        >
          {text}
        </button>
      ) : (
        <>
          <MultiButton
            selected={selected}
            name={name}
            onClick={onClick}
            text={text}
            selectedColor='btn-primary'
          />
        </>
      )}
      {lineBelow && (
        <div className='options-line-container'>
          <hr className='options-line' />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

// export const SudokuOptionsButton = ({ buttonInfo, onClick, selected }) => {
//   const { type = 'single', text, name } = buttonInfo;
//   return type === 'single' ? (
//     <button
//       className={`btn options-btn ${selected ? 'btn-dark' : 'btn-white'}`}
//       onClick={(e) => onClick(e)}
//       data-name={name}
//     >
//       {text[0]}
//     </button>
//   ) : (
//     <MultiButton
//       selected={selected}
//       name={name}
//       onClick={onClick}
//       text={text}
//       selectedColor='btn-dark'
//     />
//   );
// };

// Multi-button
export const MultiButton = ({
  selected,
  name,
  onClick,
  text,
  selectedColor,
}) => (
  <div>
    <div className='multi-btn-container'>
      {text.map((val, index) => (
        <button
          key={index}
          className={`btn ${
            selected === (index === 0) || selected === index
              ? selectedColor
              : 'btn-white'
          } options-btn multi-btn multi-btn-${text.length}`}
          onClick={(e) => onClick(e)}
          data-name={name}
          data-index={index}
        >
          {val}
        </button>
      ))}
    </div>
  </div>
);

export const Instructions = ({ puzzleMode, instructions, stateVariables }) => {
  const { optionsButtonsState } = stateVariables;

  return (
    <>
      <h1 className='medium text-primary'>Instructions</h1>
      {instructions.map((group, groupIndex) => (
        <Fragment key={groupIndex}>
          <h2 key={`i-${groupIndex}`} className='x-small text-light'>
            {group.header}
          </h2>
          {group.instructions.map(
            (instruction, instructionIndex) =>
              (instruction.conditions.length === 0 ||
                instruction.conditions.reduce(
                  (prev, current) =>
                    prev &&
                    optionsButtonsState[current.button].selected ===
                      current.value,
                  true
                )) && (
                <p
                  key={`ib-${instructionIndex}`}
                  className='x-small text-white'
                >
                  + <b key={`ibld-${instructionIndex}`}>{instruction.start}</b>{' '}
                  {instruction.value}
                </p>
              )
          )}
        </Fragment>
      ))}
    </>
  );
};
