import React from 'react';

export const HomeTitleText = ({ text }) => {
  return <h1 className='x-large text-primary'>{text}</h1>;
};

export const IconOverview = ({ icon, title, description }) => {
  return (
    <div className='skill-box col-xlg-4 col-lg-4'>
      <span className={`icon ${icon} fa-4x`}></span>
      <h3 className='skill-title'>{title}</h3>
      <p className='skill-description'>{description}</p>
    </div>
  );
};

export const LearnMoreButton = ({ href, icon, text }) => {
  return (
    <div className='col-xlg-4 col-lg-4 col-sm-12'>
      <div className='button-container'>
        <a
          className='btn btn-primary btn-lg learn-more-button'
          type='button'
          href={href}
        >
          <span className={icon}></span> &nbsp; {text}
        </a>
      </div>
    </div>
  );
};
