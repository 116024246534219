import React from 'react';

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <footer className='footer bg-dark'>
      <a href='https://www.linkedin.com/in/jeff-griffiths-5794b6ab'>
        <span className='social-icon fab fa-linkedin fa-2x'></span>
      </a>
      <a href='https://instagram.com/the_going_growing_griffiths?igshid=OGQ5ZDc2ODk2ZA=='>
        <span className='social-icon fab fa-instagram-square fa-2x'></span>
      </a>
      <a href='https://github.com/jkgriffiths93'>
        <span className='social-icon fab fa-github-square fa-2x'></span>
      </a>
      <a href='mailto: jkgriffiths93@gmail.com'>
        <span className='social-icon fas fa-envelope fa-2x'></span>
      </a>
      <p className='text-copyright'>© Copyright Jeff Griffiths {currentYear}</p>
    </footer>
  );
};

export default Footer;
